import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	modalHeroBannerImageAltText: {
		id: 'servicedesk-cmdb-data-manager-change-boarding.modal-hero-banner-image-alt-text',
		defaultMessage: 'Data Manager modal banner image',
		description: 'Image alt text description for banner image',
	},
	modalTitle: {
		id: 'servicedesk-cmdb-data-manager-change-boarding.modal-title',
		defaultMessage: 'Introducing Data Manager',
		description: 'Title for the change boarding modal appears below the modal image',
	},
	modalContent: {
		id: 'servicedesk-cmdb-data-manager-change-boarding.modal-content',
		defaultMessage:
			'<p>With Data Manager, you can now import your data from anywhere. Data Manager gathers, aggregates and analyses multiple sources of data, making it easy to pinpoint issues so you can move on to more important tasks.</p><p>Go to Assets configuration to enable Data Manager on your site.</p>',
		description: 'Content for the change boarding modal appears below the modal title',
	},
	cancelButton: {
		id: 'servicedesk-cmdb-data-manager-change-boarding.cancel-button',
		defaultMessage: 'Remind me later',
		description: 'Modal cancel button text which sits at the bottom of the modal in the centre',
	},
	confirmButton: {
		id: 'servicedesk-cmdb-data-manager-change-boarding.confirm-button',
		defaultMessage: 'Read about Data Manager',
		description: 'Modal confirm button text which sits at the bottom of the modal in the centre',
	},
});
