import React, { type PropsWithChildren } from 'react';
import { styled } from '@compiled/react';
import Tooltip, { type TooltipProps } from '@atlaskit/tooltip';

/**
 * Simple wrapper around Tooltip to include a button so that it can be focused/read by a
 * screenreader. This is useful when the element you're wrapping the tooltip around is not natively
 * focusable (like an icon)
 */
export const FocusableTooltip = ({ children, ...props }: PropsWithChildren<TooltipProps>) => (
	<Tooltip {...props}>
		<UnstyledButton>{children}</UnstyledButton>
	</Tooltip>
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const UnstyledButton = styled.button({
	display: 'block',
	border: 0,
	background: 'none',
	padding: 0,
	margin: 0,
	color: 'inherit',
});
