import React, { useState, useCallback } from 'react';
import { styled as styled2 } from '@compiled/react';
import Button from '@atlaskit/button';
import Heading from '@atlaskit/heading';
import { Box, xcss } from '@atlaskit/primitives';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { ReferencedObjectReadView } from '@atlassian/jira-servicedesk-insight-attribute-views/src/ui/referenced-object/index.tsx';
import { useLandingPageState } from '@atlassian/jira-servicedesk-insight-landing-page-store/src/controllers/store/index.tsx';
import { InsightRecentObjectContainerSkeleton } from '@atlassian/jira-skeletons/src/ui/insight-landing-container/index.tsx';
import { ErrorContent } from './error/index.tsx';
import { messages } from './messages.tsx';
import type { ObjectListProps } from './types.tsx';

const ObjectList = (props: ObjectListProps) => {
	const { referencedObjects } = props;
	const { numberOfObjectsDisplayed } = props;
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const onRecentObjectClick = useCallback(() => {
		fireUIAnalytics(createAnalyticsEvent({}), 'button clicked', 'insightRecentObjectLozenge');
	}, [createAnalyticsEvent]);

	if (referencedObjects.length === 0) {
		return <NoObjectsText>{formatMessage(messages.noObjectsToShow)}</NoObjectsText>;
	}

	return (
		<>
			{referencedObjects.slice(0, numberOfObjectsDisplayed).map((object) => (
				<ReferencedObjectReadView
					key={object.id}
					id={object.id}
					objectKey={object.objectKey}
					label={object.label}
					avatar={object.avatar}
					onClick={onRecentObjectClick}
				/>
			))}
		</>
	);
};

export const ObjectContainer = () => {
	const [numberOfObjectsDisplayed, setNumberOfObjectsDisplayed] = useState(5);
	const { formatMessage } = useIntl();
	const [{ recentObjectState }] = useLandingPageState();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const onViewMoreClick = useCallback(() => {
		setNumberOfObjectsDisplayed(numberOfObjectsDisplayed + 5);
		fireUIAnalytics(createAnalyticsEvent({}), 'button clicked', 'insightRecentObjectsViewMore');
	}, [numberOfObjectsDisplayed, createAnalyticsEvent]);

	const displayFooter =
		recentObjectState.type === 'success' &&
		recentObjectState.recentObjects.length > numberOfObjectsDisplayed;

	return (
		<>
			{recentObjectState.type === 'loading' && <InsightRecentObjectContainerSkeleton />}

			{recentObjectState.type === 'error' && <ErrorContent />}

			{recentObjectState.type === 'success' && (
				<>
					<Box xcss={listHeaderStyles}>
						<Heading as="h2" size="xsmall">
							{formatMessage(messages.objectSchemas)}
						</Heading>
					</Box>
					<ListWrapper>
						<ObjectList
							referencedObjects={recentObjectState.recentObjects}
							numberOfObjectsDisplayed={numberOfObjectsDisplayed}
						/>
					</ListWrapper>
				</>
			)}

			{displayFooter && (
				<Box xcss={listFooterStyles}>
					<Button appearance="link" spacing="none" onClick={onViewMoreClick}>
						{formatMessage(messages.viewMore)}
					</Button>
				</Box>
			)}
		</>
	);
};

const listHeaderStyles = xcss({
	marginBottom: 'space.200',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ListWrapper = styled2.div({
	rowGap: token('space.100', '8px'),
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'flex-start',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const NoObjectsText = styled2.span({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text.subtle', colors.N300),
	font: token('font.body.UNSAFE_small'),
	rowGap: 0,
});

const listFooterStyles = xcss({ marginTop: 'space.150' });
