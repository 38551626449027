import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	objects: {
		defaultMessage: '{objectNumber} {objectNumber, plural, one {object} other {objects}}',
		id: 'servicedesk-insight-schema-card.objects',
		description: 'Label that shows number of object in schema',
	},
	configuration: {
		defaultMessage: 'Configuration',
		id: 'servicedesk-insight-schema-card.configuration',
		description: 'Action button used to access the configuration of a schema',
	},
	more: {
		defaultMessage: 'More',
		id: 'servicedesk-insight-schema-card.more',
		description: 'Describes the meatball icon for accessibility',
	},
	locked: {
		defaultMessage: 'Locked',
		id: 'servicedesk-insight-schema-card.locked',
		description: 'Describes the lock icon for accessibility',
	},
	tooltipDisabled: {
		defaultMessage: "You don't have permission to edit the object schema",
		id: 'servicedesk-insight-schema-card.tooltip-disabled',
		description: 'Tooltips that describes why the user can edit the object schema',
	},
	tooltipRestrictedSchema: {
		defaultMessage: 'This system-defined schema is read-only.',
		id: 'servicedesk-insight-schema-card.tooltip-restricted-schema',
		description: 'Tooltips that describes why the user can edit the object schema',
	},
});
