import { performPostRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import { getCmdbAsyncBulkDeleteUrl } from '@atlassian/jira-servicedesk-cmdb-rest-urls/src/common/constants/object.tsx';
import type { WorkspaceId } from '@atlassian/jira-servicedesk-insight-shared-types/src/common/types/shared-types/index.tsx';
import type { BulkActionResponse } from '@atlassian/jira-servicedesk-insight-shared-types/src/common/types/task.tsx';
import { generateFilterBody } from '../common/utils/generate-filter-body/index.tsx';
import type { AsyncBulkDeleteParams } from './types.tsx';

export const createAsyncBulkDeleteTask = (
	workspaceId: WorkspaceId,
	params: AsyncBulkDeleteParams,
): Promise<BulkActionResponse> => {
	if (params.qlQueryParams == null && params.filterParams == null) {
		throw new Error(
			'Must supply either `filters` or `qlQueryParams` to `createAsyncBulkDeleteTask`',
		);
	}

	const body = generateFilterBody(params);

	return performPostRequest(getCmdbAsyncBulkDeleteUrl(workspaceId), {
		body: JSON.stringify(body),
	});
};
