import React from 'react';
import EmptyState from '@atlaskit/empty-state';
import ErrorIllustration from '@atlassian/jira-illustrations/src/ui/adg4/brand/spots/error/components/error-white/index.tsx';
import { getAkEmptyStateRenderImageFn } from '@atlassian/jira-illustrations/src/ui/helpers/ak-empty-state/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { messages } from './messages.tsx';

export const ErrorContent = () => {
	const { formatMessage } = useIntl();

	const renderImage = () =>
		getAkEmptyStateRenderImageFn(ErrorIllustration)({
			imageWidth: 164,
			maxImageWidth: 164,
			maxImageHeight: 212,
		});

	return (
		<EmptyState
			size="wide"
			renderImage={renderImage}
			header={formatMessage(messages.errorTitle)}
			description={formatMessage(messages.errorDescription)}
			testId="servicedesk-insight-schema-container.ui.error"
		/>
	);
};
