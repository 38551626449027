import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';

export const cmdbAsyncActionsResultsEntryPoint = createEntryPoint({
	root: JSResourceForInteraction(() =>
		import(/* webpackChunkName: "async-cmdb-async-actions-results-modal" */ './ui/index').then(
			(module) => module.CmdbAsyncActionsResultsModal,
		),
	),
	getPreloadProps: () => ({}),
});
