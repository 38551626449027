import React, { type ReactNode } from 'react';
import { FormattedI18nMessage } from '@atlassian/jira-formatted-i18n-message/src/ui/index.tsx';
import { useIntl } from '@atlassian/jira-intl/src/index.tsx';
import type { MessageDescriptorV2 as MessageDescriptor } from '@atlassian/jira-intl/src/v2/types.tsx';
import { Link, type LinkProps } from '@atlassian/react-resource-router';

type Props = {
	message: MessageDescriptor;
	linkProps: Flow.Diff<
		LinkProps,
		{
			children: ReactNode;
		}
	>;
};

/**
 *
 * @deprecated Use FormattedI18nMessage from
 * `@atlassian/jira-formatted-i18n-message` directly instead.
 *
 * Allows rendering a react-resource-router link in a react-intl message
 *
 * Accepts a react-intl message with link tags (`{linkTagStart}` and
 * `{linkTagEnd}`) added as replacement params. The contents of the link tags
 * will be replaced with a `react-resource-router` `<Link />` component that the
 * consumer can supply props to.
 */
export const FormattedLinkMessage = ({ message, linkProps }: Props) => {
	const { formatMessage } = useIntl();

	return (
		<FormattedI18nMessage
			message={formatMessage(message, {
				linkTagStart: '{linkTagStart}',
				linkTagEnd: '{linkTagEnd}',
			})}
			componentsMapping={{
				linkTag: ({ children }: { children: ReactNode }) => <Link {...linkProps}>{children}</Link>,
			}}
		/>
	);
};
