import React, { type ComponentType } from 'react';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { lazy } from '@atlassian/react-loosely-lazy';
import type { CBPChangeboardingProps } from './types.tsx';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const CBPChangeboardingWrapper: ComponentType<CBPChangeboardingProps> = lazy(
	() => import(/* webpackChunkName: "jsm-assets-va-changeboarding" */ './ui'),
);

export const AsyncCBPChangeboarding = (props: CBPChangeboardingProps) => (
	<Placeholder name="jsm-assets-va-changeboarding" fallback={null}>
		<CBPChangeboardingWrapper {...props} />
	</Placeholder>
);
