import { useEffect, useState } from 'react';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { PACKAGE_NAME, TEAM_NAME } from '../common/constants.tsx';
import { fetchIntercomHmac } from './rest/fetch-intercom-hmac/index.tsx';

export const useIntercomHmac = () => {
	const [intercomHmac, setIntercomHmac] = useState<string>('');
	useEffect(() => {
		(async () => {
			try {
				const res = await fetchIntercomHmac();
				setIntercomHmac(res.userIntercomHmac);
			} catch (e) {
				fireErrorAnalytics({
					meta: {
						id: 'fetchIntercomHmac',
						packageName: PACKAGE_NAME,
						teamName: TEAM_NAME,
					},
					error: e instanceof Error ? e : undefined,
					sendToPrivacyUnsafeSplunk: true,
				});
			}
		})();
	}, []);
	return {
		intercomHmac,
	};
};
