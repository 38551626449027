import type { UnusedParams } from '../types.tsx';

// The following properties are required by the endpoint but don't actually
// control the response in any way. This is why their values are hardcoded.
export const UNUSED_REQUIRED_PROPERTIES: UnusedParams = {
	asc: 1,
	attributesToDisplay: { attributesToDisplayIds: [] },
	includeAttributes: false,
	page: 1,
	resultsPerPage: 25,
};
