import React, { useState } from 'react';
import { IconButton } from '@atlaskit/button/new';
import CrossIcon from '@atlaskit/icon/core/migration/close--cross';
import { Box, xcss } from '@atlaskit/primitives';
import Anchor from '@atlaskit/primitives/anchor';
import { token } from '@atlaskit/tokens';
import { createLocalStorageProvider } from '@atlassian/jira-browser-storage-providers/src/controllers/local-storage/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { messages } from './messages.tsx';

const LOCAL_STORAGE = createLocalStorageProvider('jsm-cbp-pre-release');
const DISMISSED_KEY = 'dismissed';

export const PreReleaseBanner = ({ location }: { location: 'assets' | 'virtual-agent' }) => {
	const { formatMessage } = useIntl();
	const [isDismissed, setIsDismissed] = useState(LOCAL_STORAGE.get(DISMISSED_KEY) != null);
	const onDismiss = () => {
		LOCAL_STORAGE.set(DISMISSED_KEY, 'true');
		setIsDismissed(true);
	};
	const { createAnalyticsEvent } = useAnalyticsEvents();

	if (isDismissed) {
		return null;
	}

	// Implementation to match Atlaskit banner, because AK banner wraps the content with multiple elements without full
	// width, so it's not possible to place the dismiss button at the end.
	const banner = (
		<Box
			role="alert"
			xcss={containerStyles}
			backgroundColor="color.background.neutral.bold"
			padding="space.150"
		>
			<Box xcss={textStyles} as="span">
				{fg('virtual_agent_rebrand_to_virtual_service_agent')
					? formatMessage(messages.infoV2)
					: formatMessage(messages.info)}{' '}
				<Anchor
					href="https://www.atlassian.com/blog/announcements/jsm-cloud-pricing-packaging-update"
					target="_blank"
					rel="noopener"
					xcss={linkStyles}
					onClick={() => {
						// https://data-portal.internal.atlassian.com/analytics/registry/71908
						fireUIAnalytics(
							createAnalyticsEvent({
								action: 'clicked',
								actionSubject: 'link',
							}),
							'cbpWhatThisMeansClick',
						);
					}}
				>
					{formatMessage(messages.whatThisMeans)}
				</Anchor>
			</Box>
			<IconButton
				icon={(iconProps) => (
					<CrossIcon {...iconProps} spacing="spacious" color="var(--ds-icon-inverse)" />
				)}
				label={formatMessage(messages.dismiss)}
				onClick={onDismiss}
			/>
		</Box>
	);

	if (location === 'assets') {
		return (
			<Box xcss={negateMarginStyles}>
				<Box xcss={negateMarginInnerStyles}>{banner}</Box>
			</Box>
		);
	}
	// Add any custom wrapper styles for location === 'virtual-agent' if required
	return banner;
};

const containerStyles = xcss({
	height: '3rem',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'space-between',
});

const textStyles = xcss({
	color: 'color.text.inverse',
	font: token('font.body'),
	fontWeight: token('font.weight.medium'),
	overflow: 'hidden',
	textOverflow: 'ellipsis',
});

const linkStyles = xcss({
	color: 'color.text.inverse',
	textDecoration: 'underline',
	':hover': {
		color: 'color.text.inverse',
	},
	':focus': {
		color: 'color.text.inverse',
	},
	':active': {
		color: 'color.text.inverse',
	},
});

// nested wrappers due to no space.negative.500, maximum 400.
const negateMarginStyles = xcss({
	marginBottom: 'space.0',
	marginLeft: 'space.negative.400',
	marginRight: 'space.negative.400',
});
const negateMarginInnerStyles = xcss({
	marginLeft: 'space.negative.100',
	marginRight: 'space.negative.100',
});
