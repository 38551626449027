import React, { type ReactNode, type KeyboardEventHandler } from 'react';
import Button from '@atlaskit/button';
import DropdownMenu, { DropdownItemGroup } from '@atlaskit/dropdown-menu';
import MoreIcon from '@atlaskit/icon/glyph/more';

export type MeatballsMenuProps = {
	id?: string;
	tabIndex?: number;
	onKeyDown?: KeyboardEventHandler<HTMLButtonElement>;
	isDisabled?: boolean;
	children: ReactNode;
	moreIconLabel: string;
	position?: string;
	testId?: string;
	isOpen?: boolean;
};

export const MeatballsMenu = ({
	id,
	tabIndex,
	onKeyDown,
	moreIconLabel,
	children,
	isDisabled = false,
	testId,
	...restProps
}: MeatballsMenuProps) => (
	<DropdownMenu
		placement="bottom-end"
		trigger={({ triggerRef, ...props }) => (
			<Button
				{...props}
				id={id}
				testId={testId}
				tabIndex={tabIndex}
				iconBefore={<MoreIcon label={moreIconLabel} size="medium" />}
				ref={triggerRef}
				appearance="subtle"
				isDisabled={isDisabled}
				onKeyDown={onKeyDown}
			/>
		)}
		{...restProps}
	>
		<DropdownItemGroup>{children}</DropdownItemGroup>
	</DropdownMenu>
);
