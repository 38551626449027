import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { performDeleteRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import { fireTrackAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { getCmdbConfigStatusTypeByIdUrl } from '@atlassian/jira-servicedesk-cmdb-rest-urls/src/common/constants/config.tsx';
import type { StatusType, ActionApi } from '../../common/types.tsx';

export const deleteStatusType =
	(statusType: StatusType): ActionApi =>
	async ({ setState, getState }, { workspaceId, createAnalyticsEvent }) => {
		const state = getState();

		setState({
			...state,
			statusTypes: { ...state.statusTypes, deleteLoading: true },
		});

		try {
			await performDeleteRequest(getCmdbConfigStatusTypeByIdUrl(workspaceId, statusType.id));

			setState({
				...state,
				statusTypes: {
					...state.statusTypes,
					deleteLoading: false,
					data: state.statusTypes.data
						? state.statusTypes.data.filter(({ id }) => id !== statusType.id)
						: undefined,
				},
			});

			fireTrackAnalytics(createAnalyticsEvent({}), 'deleteStatusType succeeded');
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			setState({
				...state,
				statusTypes: {
					...state.statusTypes,
					deleteLoading: false,
					deleteError: error,
				},
			});

			fireErrorAnalytics({
				meta: {
					id: 'deleteStatusType',
					packageName: 'jiraServicedeskInsightGlobalConfigurationStore',
					teamName: 'falcons',
				},
				error,
			});

			throw error;
		}
	};
