import React, {
	forwardRef,
	useEffect,
	useImperativeHandle,
	useRef,
	type ChangeEvent,
	type KeyboardEvent,
} from 'react';
import { styled } from '@compiled/react';
import SearchIcon from '@atlaskit/icon/glyph/search';
import type { TriggerProps } from '@atlaskit/popup';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import type { RapidSearchBarProps, SearchTextfieldProps } from '../../common/types.tsx';
import messages from './messages.tsx';

type InputTriggerProps = {
	isOpen: boolean;
} & Partial<
	Flow.Diff<
		TriggerProps,
		{
			ref: TriggerProps['ref'];
		}
	>
> & {
		setPopupWidth: (newWidth: number) => void;
		value: string;
		renderSearchTextfield: RapidSearchBarProps['renderSearchTextfield'];
		onChange: (event: ChangeEvent<HTMLInputElement>) => void;
		onClick: () => void;
		onKeyDown: (event: KeyboardEvent<HTMLInputElement>) => void;
		onKeyUp: (event: KeyboardEvent<HTMLInputElement>) => void;
	};

export const TextfieldTrigger = forwardRef<HTMLElement, InputTriggerProps>(
	(
		{
			isOpen,
			setPopupWidth,
			onChange,
			onClick,
			onKeyDown,
			onKeyUp,
			value,
			renderSearchTextfield,
			...triggerProps
		},
		ref,
	) => {
		const { formatMessage } = useIntl();
		const containerRef = useRef<HTMLDivElement>(null);
		// @ts-expect-error - Type 'null' is not assignable to type 'HTMLElement'
		useImperativeHandle(ref, () => containerRef.current);

		useEffect(() => {
			if (containerRef && containerRef.current) {
				setPopupWidth(containerRef.current.clientWidth);
			}
		}, [containerRef?.current?.clientWidth, setPopupWidth]);

		const searchTextfieldProps: SearchTextfieldProps = {
			value,
			elemBeforeInput: (
				<IconWrapper>
					<SearchIcon label={formatMessage(messages.searchIconLabel)} />
				</IconWrapper>
			),
			onChange,
			onClick,
			onKeyDown,
			onKeyUp,
			maxLength: 256,
		};
		return (
			<div ref={containerRef} {...triggerProps}>
				{renderSearchTextfield({
					isOpen,
					searchTextfieldProps,
				})}
			</div>
		);
	},
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const IconWrapper = styled.div({
	display: 'flex',
	paddingLeft: token('space.100', '8px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.icon', colors.N300),
});
