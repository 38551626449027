import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	errorTitle: {
		id: 'servicedesk-insight-object-container.error.error-title',
		defaultMessage: 'There’s been a problem loading the recent objects',
		description: 'Error message instructing user that the object did not load successfully',
	},
	errorDescription: {
		id: 'servicedesk-insight-object-container.error.error-description',
		defaultMessage: 'Try refreshing the page.',
		description:
			'Error description instructing user to refresh the page to try again after an error occurred.',
	},
});
