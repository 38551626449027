import { useState, useCallback, useMemo } from 'react';
import debounce from 'lodash/debounce';
import type { WorkspaceId } from '@atlassian/jira-servicedesk-insight-shared-types/src/common/types/shared-types/index.tsx';
import { MIN_QUERY_CHARS } from '../common/constants.tsx';
import type { RapidSearch, SearchState, SearchResult } from '../common/types.tsx';
import { fetchRapidSearchObjects, fetchRapidSearchObjectTypes } from '../services/index.tsx';

type State = {
	query: string;
	searchState: SearchState;
};

const EMPTY_SEARCH_RESULT: SearchResult = {
	objects: [],
	objectTypes: [],
};

const DEBOUNCE_MILLIS = 300;
const queriesAreEquivalent = (firstQuery: string, secondQuery: string): boolean =>
	firstQuery.trim() === secondQuery.trim();

export const useRapidSearch = (workspaceId: WorkspaceId): RapidSearch => {
	const [state, setState] = useState<State>({ query: '', searchState: { type: 'loading' } });

	const search = useCallback(
		async (newQuery: string) => {
			const updateSearchState = (newSearchState: SearchState) => {
				setState((prevState) => {
					if (!queriesAreEquivalent(prevState.query, newQuery)) {
						// The query has changed since this search was kicked off, discard the current (stale) results
						return prevState;
					}
					return {
						query: prevState.query,
						searchState: newSearchState,
					};
				});
			};

			try {
				const objectsPromise = fetchRapidSearchObjects({
					workspaceId,
					query: newQuery.trim(),
				});
				const objectTypesPromise = fetchRapidSearchObjectTypes({
					workspaceId,
					query: newQuery.trim(),
				});
				const [objectsResponse, objectTypesResponse] = await Promise.all([
					objectsPromise,
					objectTypesPromise,
				]);

				const searchResult: SearchResult = {
					objects: objectsResponse.values,
					objectTypes: objectTypesResponse.values,
				};
				updateSearchState({ type: 'success', searchResult });
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (error: any) {
				updateSearchState({ type: 'error', error });
			}
		},
		[workspaceId, setState],
	);
	const debouncedSearch = useMemo(() => debounce(search, DEBOUNCE_MILLIS), [search]);
	const setQuery = (newQuery: string) => {
		if (newQuery.trim().length < MIN_QUERY_CHARS) {
			// Skip searching if query is too short
			setState({
				query: newQuery,
				searchState: { type: 'success', searchResult: EMPTY_SEARCH_RESULT },
			});
			return;
		}
		if (queriesAreEquivalent(state.query, newQuery)) {
			setState({
				query: newQuery,
				searchState: state.searchState,
			});
			return;
		}

		setState({
			query: newQuery,
			searchState: { type: 'loading' },
		});
		debouncedSearch(newQuery);
	};

	return { query: state.query, setQuery, searchState: state.searchState };
};
