import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	infoV2: {
		id: 'servicedesk-feature-usage-common.pre-release-banner.info-v-2',
		defaultMessage: 'Assets and the virtual service agent are moving to consumption-based pricing.',
		description:
			'Informs the user that assets and the virtual service agent are moving to consumption-based pricing.',
	},
	info: {
		id: 'servicedesk-feature-usage-common.pre-release-banner.info',
		defaultMessage: 'Assets and the virtual service agent are moving to consumption-based pricing.',
		description:
			'Informs the user that assets and the virtual agent are moving to consumption-based pricing.',
	},
	whatThisMeans: {
		id: 'servicedesk-feature-usage-common.pre-release-banner.what-this-means',
		defaultMessage: 'What this means for your site.',
		description:
			"Text for a link that explains the implications of consumption-based pricing for the user's site",
	},
	dismiss: {
		id: 'servicedesk-feature-usage-common.pre-release-banner.dismiss',
		defaultMessage: 'Dismiss',
		description: 'Text for a button that hides the banner for the user',
	},
});
