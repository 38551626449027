import type { Filter } from '../../../types.tsx';
import { UNUSED_REQUIRED_PROPERTIES } from '../../constants.tsx';

export const generateFilterBody = (filter: Filter) => {
	const body = { ...filter };

	if (filter.qlQuerySearch && filter.qlQueryParams) {
		body.qlQueryParams = {
			...filter.qlQueryParams,
			...UNUSED_REQUIRED_PROPERTIES,
		};
	} else if (!filter.qlQuerySearch && filter.filterParams) {
		body.filterParams = {
			...filter.filterParams,
			...UNUSED_REQUIRED_PROPERTIES,
		};
	} else {
		throw new Error(
			`Invalid state, qlQuerySearch: ${filter.qlQuerySearch}, qlQueryParams: ${filter.qlQueryParams}, filterParams: ${filter.filterParams}`,
		);
	}
	return body;
};
