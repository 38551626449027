import type { ReferenceType, StatusTypeResponse } from '../types.tsx';

export const sortReferenceTypes = (referenceTypes: ReferenceType[]): ReferenceType[] =>
	referenceTypes
		.sort((a, b) => a.name.localeCompare(b.name))
		.sort((a, b) => {
			if (a.removable === b.removable) {
				return 0;
			}

			return a.removable ? 1 : -1;
		});

export const sortStatusTypes = (statusTypes: StatusTypeResponse[]): StatusTypeResponse[] =>
	statusTypes.sort((a, b) => a.name.localeCompare(b.name));
