import type { CreateUIAnalyticsEvent } from '@atlaskit/analytics-next';
import type { CmdbLimitInfo } from '@atlassian/jira-servicedesk-insight-shared-types/src/common/types/global-config.tsx';
import type { RoleActorType } from '@atlassian/jira-servicedesk-insight-shared-types/src/common/types/insight-roles.tsx';
import type { WorkspaceId } from '@atlassian/jira-servicedesk-insight-shared-types/src/common/types/shared-types/index.tsx';
import type { Action, StoreActionApi } from '@atlassian/react-sweet-state';
import type { DATA_MANAGER_GA_FF } from './constants.tsx';

export type ReferenceType = {
	color: string;
	description: string;
	globalId: string;
	id: string;
	name: string;
	removable: boolean;
	url16: string;
	workspaceId: WorkspaceId;
};

export type StatusType = {
	id: string;
	name: string;
	description: string;
	category: number;
};

export type StatusTypeResponse = {
	workspaceId: WorkspaceId;
	globalId: string;
} & StatusType;

export type IconType = {
	id: string;
	name: string;
	url16: string;
	url48: string;
};

export type NewIconType = {
	blob: Blob;
	fileName: string;
	iconName: string;
};

type InsightMediaAuthenticationResult = {
	clientId: string;
	issuer: MediaIssuer;
	mediaBaseUrl: MediaBaseUrl;
	mediaJwtToken: MediaJwt;
	tokenLifespanInMinutes: number;
};

export type InsightMediaAuthenticationUpload = InsightMediaAuthenticationResult & {
	collectionId: CollectionId;
};

export type InsightMediaAuthenticationFetch = InsightMediaAuthenticationResult;

export const MEDIA_PROCESS_STATUS = {
	SUCCEEDED: 'succeeded',
	FAILED: 'failed',
	PENDING: 'pending',
	RUNNING: 'running',
} as const;

export type MediaIconUploadResult = {
	data: {
		mediaType: string;
		mimeType: string;
		name: string;
		size: number;
		processingStatus: (typeof MEDIA_PROCESS_STATUS)[keyof typeof MEDIA_PROCESS_STATUS];
		createdAt: number;
		id: string;
	};
};

export type MediaBaseUrl = string;
export type CollectionId = string;
export type FileName = string;
export type IconName = string;
export type MediaIssuer = string;
export type MediaJwt = string;
export type MediaFileUuid = string;
export type MediaClientId = string;

export const toMediaBaseUrl = (url: string | MediaBaseUrl): MediaBaseUrl => url;
export const toCollectionId = (uuid: string | CollectionId): CollectionId => uuid;
export const toIconName = (iconName: string | IconName): IconName => iconName;
export const toFileName = (fileName: string | FileName): FileName => fileName;
export const toMediaIssuer = (mediaIssuer: string | MediaIssuer): MediaIssuer => mediaIssuer;
export const toMediaJwt = (mediaIssuer: string | MediaJwt): MediaJwt => mediaIssuer;
export const toMediaFileUuid = (mediaFileUuid: string | MediaFileUuid): MediaFileUuid =>
	mediaFileUuid;
export const toMediaClientId = (mediaClientId: string | MediaClientId): MediaClientId =>
	mediaClientId;

// Only add properties to this type as they're used throughout Insight. We want
// to be able to see what is still in use at the end of the modernisation of Insight.

export type GlobalConfig = {
	restrictedObjectSchemaIds: number[];
	insightAdministrator: boolean;
	insightReporting: boolean;
	referenceTypes: ReferenceType[];
	statusTypes: StatusTypeResponse[];
	icons: IconType[];
	limitInfo: CmdbLimitInfo;
};

export type ReferenceTypesState = {
	data: undefined | ReferenceType[];
	createLoading: boolean;
	createError: undefined | Error;
	deleteLoading: boolean;
	deleteError: undefined | Error;
	updateLoading: boolean;
	updateError: undefined | Error;
};

export type StatusTypesState = {
	data: undefined | StatusTypeResponse[];
	createLoading: boolean;
	createError: undefined | Error;
	deleteLoading: boolean;
	deleteError: undefined | Error;
	updateLoading: boolean;
	updateError: undefined | Error;
};

export type IconsState = {
	data: undefined | IconType[];
	deleteLoading: boolean;
	deleteError: undefined | Error;
	updateLoading: boolean;
	updateError: undefined | Error;
	addLoading: boolean;
	addError: undefined | Error;
};

type DataManagerKeys = typeof DATA_MANAGER_GA_FF;

type DataManagerFFList = {
	[key in DataManagerKeys]: boolean;
};

export type DataManagerState = {
	rolesData: RoleDetails[] | undefined;
	rolesError: undefined | Error;
	rolesLoading: boolean;
	rolesUpdateLoading: boolean;
	rolesUpdateError: undefined | Error;
	tokensData: TokenDetails[] | undefined;
	tokensLoading: boolean;
	tokensError: undefined | Error;
	tokenRevokeLoading: boolean;
	tokenRevokeError: undefined | Error;
	isProvisioned?: boolean;
	provisioningLoading: boolean;
	provisioningError: undefined | Error;
	provisioningUpdateLoading: boolean;
	provisioningUpdateError: undefined | Error;
	myRoles: string[] | undefined;
	myRolesError: undefined | Error;
	myRolesLoading: boolean;
	featureFlags: DataManagerFFList;
};

export type State = {
	loading: boolean;
	error: undefined | Error;
	config: undefined | GlobalConfig;
	referenceTypes: ReferenceTypesState;
	statusTypes: StatusTypesState;
	icons: IconsState;
	dataManager: DataManagerState;
};

export type ContainerProps = {
	workspaceId: WorkspaceId;
	createAnalyticsEvent: CreateUIAnalyticsEvent;
	disableOnInit?: boolean;
};

export type ActionApi = Action<State, ContainerProps>;

export type StoreApi = StoreActionApi<State>;

export type RoleProperties = {
	users?: RoleActorType[];
	groups?: RoleActorType[];
	roleId: string;
};

export type UserPrincipalDetails = {
	accountId: string;
	name: string;
	picture?: string;
};
export type GroupPrincipalDetails = {
	id: string;
	displayName: string;
};

export type PrincipalsForRolesResponse = {
	results: RoleDetails[];
};

export type MyRolesResponse = {
	results: string[];
};

export type ProvisioningStatusResponse = {
	enabled: ProvisioningStatus;
};
export type ProvisioningStatus = 'ON' | 'OFF' | 'EMPTY';

export type RoleDetails = {
	roleId: string;
	resourceId: string;
	userPrincipalDetails: UserPrincipalDetails[];
	groupPrincipalDetails: GroupPrincipalDetails[];
};

export type UpdateRoleAssignmentResponse = {
	tokens: string[];
};

export type TokenDetails = {
	id: string;
	type: string;
	label: string;
};

export type FetchTokensResponse = {
	tokens: TokenDetails[];
};
