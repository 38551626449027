/** @jsx jsx */
import React, { useEffect, useState, type KeyboardEvent, type ChangeEvent } from 'react';
import { jsx } from '@compiled/react';
import Popup from '@atlaskit/popup';
import { Box, xcss } from '@atlaskit/primitives';
import { useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { rapidSearchExperience } from '@atlassian/jira-servicedesk-insight-experiences/src/index.tsx';
import {
	toCmdbObjectId,
	toCmdbObjectTypeId,
	toSchemaId,
} from '@atlassian/jira-servicedesk-insight-shared-types/src/common/types/shared-types/index.tsx';
import {
	createObjectDetailUrl,
	createObjectTypeUrl,
} from '@atlassian/jira-servicedesk-insight-urls/src/index.tsx';
import { useRouterActions } from '@atlassian/react-resource-router';
import { MIN_QUERY_CHARS } from '../common/constants.tsx';
import type { RapidSearchBarProps } from '../common/types.tsx';
import { fireObjectClickedUiEvent, fireObjectTypeClickedUiEvent } from '../common/utils.tsx';
import { useRapidSearch } from '../controllers/index.tsx';
import { PopupContent } from './popup-content/index.tsx';
import { TextfieldTrigger } from './trigger/index.tsx';

const isNotBlank = (value: string): boolean => value.trim().length > 0;

export const RapidSearchBar = ({
	workspaceId,
	restrictedObjectSchemaIds,
	renderSearchTextfield,
}: RapidSearchBarProps) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { push } = useRouterActions();
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const [popupWidth, setPopupWidth] = useState<number>(400);
	const { query, setQuery, searchState } = useRapidSearch(workspaceId);
	const [selectedIndex, setSelectedIndex] = useState<number | null>(null);

	useEffect(() => {
		// Clear selection after search changes
		setSelectedIndex(null);
	}, [searchState, setSelectedIndex]);

	const onNavigateToResult = () => {
		setIsOpen(false);
		setQuery('');
		setSelectedIndex(null);
	};

	const onKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
		switch (event.key) {
			case 'ArrowDown':
				if (searchState.type === 'success') {
					const { objects, objectTypes } = searchState.searchResult;
					event.preventDefault();
					if (selectedIndex !== null) {
						setSelectedIndex(Math.min(selectedIndex + 1, objects.length + objectTypes.length - 1));
					} else {
						setSelectedIndex(0);
					}
				}
				break;
			case 'ArrowUp':
				event.preventDefault();
				if (selectedIndex !== null) {
					setSelectedIndex(Math.max(0, selectedIndex - 1));
				} else {
					setSelectedIndex(0);
				}
				break;
			case 'Enter': {
				if (searchState.type === 'success' && selectedIndex !== null) {
					const { objects, objectTypes } = searchState.searchResult;
					if (selectedIndex < objects.length) {
						const object = objects[selectedIndex];
						fireObjectClickedUiEvent({
							analyticsEvent: createAnalyticsEvent({}),
							objectId: toCmdbObjectId(object.id),
							schemaId: toSchemaId(object.objectSchemaId),
							restrictedObjectSchemaIds,
						});
						push(createObjectDetailUrl(toCmdbObjectId(object.id)));
						onNavigateToResult();
					} else {
						const objectType = objectTypes[selectedIndex - objects.length];
						fireObjectTypeClickedUiEvent({
							analyticsEvent: createAnalyticsEvent({}),
							objectTypeId: toCmdbObjectTypeId(objectType.id),
							schemaId: toSchemaId(objectType.objectSchemaId),
							restrictedObjectSchemaIds,
						});
						push(
							createObjectTypeUrl(
								toSchemaId(objectType.objectSchemaId),
								toCmdbObjectTypeId(objectType.id),
							),
						);
						onNavigateToResult();
					}
				}
				break;
			}
			case 'Esc':
			case 'Escape':
				setQuery('');
				setSelectedIndex(null);
				break;
			default:
		}
	};

	const onKeyUp = (event: KeyboardEvent<HTMLInputElement>) => {
		switch (event.key) {
			case 'Esc':
			case 'Escape':
				// Prevents input from losing focus when hitting ESC to close the popup
				event.stopPropagation();
				event.nativeEvent.stopImmediatePropagation();
				break;
			default:
		}
	};

	const onChange = (event: ChangeEvent<HTMLInputElement>) => {
		const userInput = event.target.value;

		if (userInput.trim().length >= MIN_QUERY_CHARS) {
			rapidSearchExperience.start();
		}

		setIsOpen(isNotBlank(userInput));
		setQuery(userInput);
		setSelectedIndex(null);
	};

	return (
		<Popup
			isOpen={isOpen}
			autoFocus={false}
			onClose={() => setIsOpen(false)}
			content={() => (
				<Box
					xcss={[
						popupWrapperStyles,
						// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766
						xcss({
							// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
							width: `${popupWidth}px`,
						}),
					]}
				>
					<PopupContent
						query={query}
						searchState={searchState}
						selectedIndex={selectedIndex}
						restrictedObjectSchemaIds={restrictedObjectSchemaIds}
						onResultClick={onNavigateToResult}
					/>
				</Box>
			)}
			trigger={(triggerProps) => (
				<TextfieldTrigger
					{...triggerProps}
					isOpen={isOpen}
					onClick={() => setIsOpen(isNotBlank(query))}
					onChange={onChange}
					onKeyDown={onKeyDown}
					onKeyUp={onKeyUp}
					value={query}
					setPopupWidth={setPopupWidth}
					renderSearchTextfield={renderSearchTextfield}
				/>
			)}
			placement="bottom-start"
			testId="servicedesk-insight-rapid-search-bar.ui.popup"
		/>
	);
};

const popupWrapperStyles = xcss({
	overflow: 'auto',
});
