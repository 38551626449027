import { CancelledPollingError } from '../../../common/errors.tsx';
import type { CmdbAsyncActionStoreAction } from '../../types.tsx';

export const cancelledPollingError = new CancelledPollingError('Polling cancelled');

export const cancelPolling =
	(): CmdbAsyncActionStoreAction =>
	async ({ getState, setState }) => {
		if (getState().isPolling) {
			getState().pollingTimeoutIds?.forEach((timeoutId) => {
				clearTimeout(timeoutId);
			});
			setState({ isPolling: false });
			getState().pollingReject?.(cancelledPollingError);
		}
	};
