import { createLocalStorageProvider } from '@atlassian/jira-browser-storage-providers/src/controllers/local-storage/index.tsx';
import type { ObjectUsageInfo, State } from './types.tsx';

export const LOCAL_STORAGE = createLocalStorageProvider('jsm-assets-object-usage-info');
export const DISMISSED_FOR_THRESHOLD_TYPE_KEY = 'dismissed';
export const NONE: ObjectUsageInfo = { type: 'none' };

export const initialState: State = {
	userRole: 'agent',
	billingPeriod: 'monthly',
	objectCount: 0,
	objectLimit: Number.MAX_SAFE_INTEGER,
	isDismissed: false,
	limitState: 'disabled',
	workspaceId: '',
};
