import { stringify as qsStringify } from 'query-string';
import type {
	CmdbObjectTypeId,
	SchemaId,
	WorkspaceId,
} from '@atlassian/jira-servicedesk-insight-shared-types/src/common/types/shared-types/index.tsx';
import type {
	ConfigReferenceTypeRequest,
	ConfigStatusTypePickerRequest,
	ConfigStatusTypeRequest,
} from '../types/config.tsx';
import { getCmdbRestBaseUrl } from './index.tsx';

/**
 * /gateway/api/jsm/assets/workspace/${workspaceId}/v1/config
 */
const getCmdbConfigUrl = (workspaceId: WorkspaceId) => `${getCmdbRestBaseUrl(workspaceId)}/config`;
/**
 * /gateway/api/jsm/assets/workspace/${workspaceId}/v1/config/statustype
 */
export const getCmdbConfigStatusTypeUrl = (
	workspaceId: WorkspaceId,
	queryParams?: ConfigStatusTypeRequest,
) =>
	`${getCmdbConfigUrl(workspaceId)}/statustype${queryParams ? `?${qsStringify(queryParams)}` : ''}`;
/**
 * /gateway/api/jsm/assets/workspace/${workspaceId}/v1/config/statustype/${statusTypeId}
 */
export const getCmdbConfigStatusTypeByIdUrl = (workspaceId: WorkspaceId, statusTypeId: string) =>
	`${getCmdbConfigUrl(workspaceId)}/statustype/${statusTypeId}`;
/**
 * /gateway/api/jsm/assets/workspace/${workspaceId}/v1/config/statustype/picker
 */
export const getCmdbConfigStatusTypePickerUrl = (
	workspaceId: WorkspaceId,
	queryParams: ConfigStatusTypePickerRequest,
) => `${getCmdbConfigUrl(workspaceId)}/statustype/picker?${qsStringify(queryParams)}`;
/**
 * /gateway/api/jsm/assets/workspace/${workspaceId}/v1/config/referencetype
 */
export const getCmdbConfigReferenceTypeUrl = (
	workspaceId: WorkspaceId,
	queryParams?: ConfigReferenceTypeRequest,
) =>
	`${getCmdbConfigUrl(workspaceId)}/referencetype${
		queryParams ? `?${qsStringify(queryParams)}` : ''
	}`;
/**
 * /gateway/api/jsm/assets/workspace/${workspaceId}/v1/config/referencetype/${referenceTypeId}
 */
export const getCmdbConfigReferenceTypeByIdUrl = (
	workspaceId: WorkspaceId,
	referenceTypeId: string,
) => `${getCmdbConfigUrl(workspaceId)}/referencetype/${referenceTypeId}`;
/**
 * /gateway/api/jsm/assets/workspace/${workspaceId}/v1/config/role/${roleId}
 */
export const getCmdbConfigRoleByIdUrl = (workspaceId: WorkspaceId, roleId: string) =>
	`${getCmdbConfigUrl(workspaceId)}/role/${roleId}`;
/**
 * /gateway/api/jsm/assets/workspace/${workspaceId}/v1/config/role/objectschema/${objectSchemaId}
 */
export const getCmdbConfigRoleObjectSchemaByIdUrl = (
	workspaceId: WorkspaceId,
	objectSchemaId: SchemaId,
) => `${getCmdbConfigUrl(workspaceId)}/role/objectschema/${objectSchemaId}`;
/**
 * /gateway/api/jsm/assets/workspace/${workspaceId}/v1/config/role/objecttype/${objectTypeId}
 */
export const getCmdbConfigRoleObjectTypeByIdUrl = (
	workspaceId: WorkspaceId,
	objectTypeId: CmdbObjectTypeId,
) => `${getCmdbConfigUrl(workspaceId)}/role/objecttype/${objectTypeId}`;
/**
 * /gateway/api/jsm/assets/workspace/${workspaceId}/v1/config/perms/principal
 */
export const getCmdbPermsPrincipalUrl = (workspaceId: WorkspaceId) =>
	`${getCmdbConfigUrl(workspaceId)}/perms/principal`;
/**
 * /gateway/api/jsm/assets/workspace/${workspaceId}/v1/config/perms/role-assignment
 */
export const getCmdbPermsRoleAssignmentUrl = (workspaceId: WorkspaceId) =>
	`${getCmdbConfigUrl(workspaceId)}/perms/role-assignment`;

/**
 * /gateway/api/jsm/assets/workspace/${workspaceId}/v1/config/perms/token-status?token=${token}
 */
export const getCmdbPermsTokenStatusUrl = (workspaceId: WorkspaceId, token: string) =>
	`${getCmdbConfigUrl(workspaceId)}/perms/token-status?token=${token}`;

/**
 * /gateway/api/jsm/assets/workspace/${workspaceId}/v1/config/perms/my-roles
 */
export const getMyDataManagerRolesUrl = (workspaceId: WorkspaceId) =>
	`${getCmdbConfigUrl(workspaceId)}/perms/my-roles`;
