import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	objectSchemas: {
		id: 'servicedesk-insight-schema-container.object-schemas',
		defaultMessage: 'Object schemas',
		description: 'Title of the object schema list',
	},
	addSchema: {
		id: 'servicedesk-insight-schema-container.add-schema',
		defaultMessage: 'Add schema',
		description: 'Describes the add schema icon for accessibility',
	},
	createSchema: {
		id: 'servicedesk-insight-schema-container.create-schema',
		defaultMessage: 'Create schema',
		description: 'Label for button that opens the create schema experience',
	},
	schemaLimitReached: {
		id: 'servicedesk-insight-schema-container.schema-limit-reached',
		defaultMessage: 'Schema limit reached',
		description: 'Label replacing "Create schema" button when the schema limit is reached',
	},
	schemaLimitErrorMessage: {
		id: 'servicedesk-insight-schema-container.schema-limit-error-message',
		defaultMessage:
			'You’ve reached your site’s maximum limit of {limit} {limit, plural, one {object schema} other {object schemas}}. Reduce the number of schemas or contact support.',
		description: 'Error message displayed when the schema limit is reached',
	},
});
