import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	errorTitle: {
		id: 'servicedesk-insight-schema-container.error.error-title',
		defaultMessage: 'There’s been a problem loading the object schemas',
		description: 'Error message instructing user that the object schema did not load successfully',
	},
	errorDescription: {
		id: 'servicedesk-insight-schema-container.error.error-description',
		defaultMessage: 'Try refreshing the page.',
		description:
			'Error description instructing user to refresh the page to try again after an error occurred.',
	},
});
