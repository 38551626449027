import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	submitSuccessFlagTitleBulkExport: {
		id: 'servicedesk-cmdb-async-actions.common.submit-success-flag-title-bulk-export',
		defaultMessage:
			'Action started: Export {objectCount, plural, one{{objectCount} object} other{{objectCount} objects}}',
		description: 'Success flag description after creating bulk export async task successfully',
	},
	submitSuccessFlagTitleBulkQrCode: {
		id: 'servicedesk-cmdb-async-actions.common.submit-success-flag-title-bulk-qr-code',
		defaultMessage:
			'Action started: Print QR code for {objectCount, plural, one{{objectCount} object} other{{objectCount} objects}}',
		description: 'Success flag description after creating bulk QR code async task successfully',
	},
	submitSuccessFlagTitleBulkDelete: {
		id: 'servicedesk-cmdb-async-actions.common.submit-success-flag-title-bulk-delete',
		defaultMessage:
			'Action started: Delete {objectCount, plural, one{{objectCount} object} other{{objectCount} objects}}',
		description: 'Success flag description after creating bulk delete async task successfully',
	},
	submitSuccessFlagTitleBulkEdit: {
		id: 'servicedesk-cmdb-async-actions.common.submit-success-flag-title-bulk-edit',
		defaultMessage:
			'Action started: Edit {objectCount, plural, one{{objectCount} object} other{{objectCount} objects}}',
		description: 'Success flag description after creating bulk edit async task successfully',
	},
	submitSuccessFlagDescription: {
		id: 'servicedesk-cmdb-async-actions.common.submit-success-flag-description',
		defaultMessage:
			'This action may take a while. You will receive a notification once the work is complete.',
		description: 'Success flag description after creating bulk async task successfully',
	},
	submitErrorFlagTitleBulkExport: {
		id: 'servicedesk-cmdb-async-actions.common.submit-error-flag-title-bulk-export',
		defaultMessage:
			'Action failed: Export {objectCount, plural, one{{objectCount} object} other{{objectCount} objects}}',
		description: 'Error flag description after creating bulk export async task failure',
	},
	submitErrorFlagTitleBulkQrCode: {
		id: 'servicedesk-cmdb-async-actions.common.submit-error-flag-title-bulk-qr-code',
		defaultMessage:
			'Action failed: Print QR code for {objectCount, plural, one{{objectCount} object} other{{objectCount} objects}}',
		description: 'Error flag description after creating bulk QR code async task failure',
	},
	submitErrorFlagTitleBulkDelete: {
		id: 'servicedesk-cmdb-async-actions.common.submit-error-flag-title-bulk-delete',
		defaultMessage:
			'Action failed: Delete {objectCount, plural, one{{objectCount} object} other{{objectCount} objects}}',
		description: 'Error flag description after creating bulk delete async task failure',
	},
	submitErrorFlagTitleBulkEdit: {
		id: 'servicedesk-cmdb-async-actions.common.submit-error-flag-title-bulk-edit',
		defaultMessage:
			'Action failed: Edit {objectCount, plural, one{{objectCount} object} other{{objectCount} objects}}',
		description: 'Error flag description after creating bulk edit async task failure',
	},
	submitErrorFlagDescription: {
		id: 'servicedesk-cmdb-async-actions.common.submit-error-flag-description',
		defaultMessage: 'Please check your network and try again',
		description: 'Error flag description after creating bulk async task failure',
	},
});
