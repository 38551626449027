import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { fireTrackAnalytics } from '@atlassian/jira-product-analytics-bridge';
import {
	OPERATION_TYPE_BULK_EXPORT,
	STATUS_QUEUED,
} from '@atlassian/jira-servicedesk-insight-shared-types/src/common/types/task.tsx';
import { mergeTasks } from '../../../common/utils/merge-tasks/index.tsx';
import { createAsyncBulkExportTask } from '../../services/create-async-bulk-export-task/index.tsx';
import type { AsyncBulkExportParams } from '../../services/create-async-bulk-export-task/types.tsx';
import type { CmdbAsyncActionStoreAction } from '../../types.tsx';
import { showErrorFlag } from '../show-error-flag/index.tsx';
import { showSuccessFlag } from '../show-success-flag/index.tsx';
import { startTaskPolling } from '../start-task-polling/index.tsx';

export type SubmitBulkExportTaskProps = {
	objectCount: string;
} & AsyncBulkExportParams;

export const submitBulkExportTask =
	({
		objectCount,
		...body
	}: SubmitBulkExportTaskProps): CmdbAsyncActionStoreAction<Promise<void>> =>
	async ({ getState, setState, dispatch }, { workspaceId, createAnalyticsEvent }) => {
		if (getState().bulkExport.isSubmitting) {
			return;
		}

		setState({ bulkExport: { isSubmitting: true } });

		try {
			const { taskId } = await createAsyncBulkExportTask(workspaceId, { ...body });

			setState({
				tasks: mergeTasks(getState().tasks, [
					{
						identifier: taskId,
						operationType: OPERATION_TYPE_BULK_EXPORT,
						status: STATUS_QUEUED,
					},
				]),
				bulkExport: { isSubmitting: false },
			});

			fireTrackAnalytics(createAnalyticsEvent({}), 'submitAsyncBulkExportTask succeeded');
			dispatch(
				showSuccessFlag({
					id: taskId,
					objectCount,
					action: OPERATION_TYPE_BULK_EXPORT,
				}),
			);

			dispatch(startTaskPolling());
		} catch (error) {
			fireErrorAnalytics({
				error: error instanceof Error ? error : undefined,
				meta: {
					id: 'submitAsyncBulkExportTask',
					packageName: 'jiraServicedeskCmdbAsyncActions',
					teamName: 'falcons',
				},
			});

			setState({ bulkExport: { isSubmitting: false } });

			dispatch(showErrorFlag({ action: OPERATION_TYPE_BULK_EXPORT, objectCount }));

			throw error;
		}
	};
