import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	descriptionCmdbAssets: {
		id: 'servicedesk-insight-landing-page.description-cmdb-assets',
		defaultMessage:
			'Connect, track and manage the assets that matter to your team - application services, infrastructure, hardware, people, and much more. {linkTagStart}Learn more about Assets{linkTagEnd}',
		description: 'A description for the page.',
	},
	navRefreshAssetsSchemasTile: {
		id: 'servicedesk-insight-landing-page.nav-refresh-assets-schemas-tile',
		defaultMessage: 'Schemas',
		description: 'Document title for the new CMDB Assets schemas page',
	},
	assetsBreadcrumbs: {
		id: 'servicedesk-insight-landing-page.assets-breadcrumbs',
		defaultMessage: 'Assets',
		description: 'Breadcrumbs text on the schemas page',
	},
	documentTitleCmdbAssets: {
		id: 'servicedesk-insight-landing-page.document-title-cmdb-assets',
		defaultMessage: 'Asset and configuration management',
		description: 'Document title for the CMDB landing page',
	},
	configLink: {
		id: 'servicedesk-insight-landing-page.config-link',
		defaultMessage: 'Configuration', // to be changed to 'Settings' later
		description: 'Label for the link to global configuration.',
	},
	searchPlaceholder: {
		id: 'servicedesk-insight-landing-page.search-placeholder',
		defaultMessage: 'Search all schemas for objects...',
		description: 'Placeholder text shown in the CMDB rapid search bar',
	},
	searchPlaceholderAriaLabel: {
		id: 'servicedesk-insight-landing-page.search-placeholder-aria-label',
		defaultMessage:
			'Start typing, then use the up and down arrows to select an option from the list',
		description: 'Placeholder text shown in the CMDB rapid search bar',
	},
	reportsLink: {
		id: 'servicedesk-insight-landing-page.reports-link',
		defaultMessage: 'Reports',
		description: 'Label for the link to Assets Reports.',
	},
	dataManagerLink: {
		id: 'servicedesk-insight-landing-page.data-manager-link',
		defaultMessage: 'Data Manager',
		description: 'Label for the link to Data Manager.',
	},
	adaptersLink: {
		id: 'servicedesk-insight-landing-page.adapters-link',
		defaultMessage: 'Adapters',
		description: 'Label for the link to Adapters.',
	},
});
