import React from 'react';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { lazy } from '@atlassian/react-loosely-lazy';
import type { CmdbGettingStartedProps } from './ui/index.tsx';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const LazyCmdbGettingStarted = lazy(() =>
	import(/* webpackChunkName: "cmdb-getting-started" */ './ui').then(
		(module) => module.CmdbGettingStarted,
	),
);

export const AsyncCmdbGettingStarted = (props: CmdbGettingStartedProps) => (
	<Placeholder name="cmdb-getting-started" fallback={null}>
		<LazyCmdbGettingStarted {...props} />
	</Placeholder>
);
