import {
	STATUS_COMPLETED,
	type CompletedTask,
	type Task,
	type TaskId,
} from '@atlassian/jira-servicedesk-insight-shared-types/src/common/types/task.tsx';

export const getCompletedTasks = (existingTasks?: Task[], newTasks?: Task[]): CompletedTask[] => {
	const existingTaskMap = new Map<TaskId, Task>();
	(existingTasks ?? []).forEach((task) => existingTaskMap.set(task.identifier, task));

	const newCompletedTasks = (newTasks ?? []).filter(
		(task): task is CompletedTask => task.status === STATUS_COMPLETED,
	);
	const tasksToUpdate: CompletedTask[] = [];
	newCompletedTasks.forEach((completedTask) => {
		const existingTask = existingTaskMap.get(completedTask.identifier);
		if (!existingTask) {
			// Task completed but didn't exist in state
			tasksToUpdate.push(completedTask);
		} else if (existingTask.status !== STATUS_COMPLETED) {
			// Task in state was not completed but is now completed server side
			tasksToUpdate.push(completedTask);
		}
	});

	return tasksToUpdate;
};
