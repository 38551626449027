import type { WorkspaceId } from '@atlassian/jira-servicedesk-insight-shared-types/src/common/types/shared-types/index.tsx';
import { getCmdbRestBaseUrl } from './index.tsx';

/**
 * /gateway/api/jsm/assets/workspace/${workspaceId}/v1/icon
 */
const getCmdbIconUrl = (workspaceId: WorkspaceId) => `${getCmdbRestBaseUrl(workspaceId)}/icon`;
/**
 * /gateway/api/jsm/assets/workspace/${workspaceId}/v1/icon/create
 */
export const getCmdbIconCreateUrl = (workspaceId: WorkspaceId) =>
	`${getCmdbIconUrl(workspaceId)}/create`;
/**
 * /gateway/api/jsm/assets/workspace/${workspaceId}/v1/icon/token-for-fetching-icon/${fileUuid}
 */
export const getCmdbIconTokenFetchingUrl = (workspaceId: WorkspaceId, fileUuid: string) =>
	`${getCmdbIconUrl(workspaceId)}/token-for-fetching-icon/${fileUuid}`;
/**
 * /gateway/api/jsm/assets/workspace/${workspaceId}/v1/icon/token-for-uploading-icon
 */
export const getCmdbIconTokenUploadingUrl = (workspaceId: WorkspaceId) =>
	`${getCmdbIconUrl(workspaceId)}/token-for-uploading-icon`;
/**
 * /gateway/api/jsm/assets/workspace/${workspaceId}/v1/icon/${iconId}
 */
export const getCmdbIconByIdUrl = (workspaceId: WorkspaceId, iconId: string) =>
	`${getCmdbIconUrl(workspaceId)}/${iconId}`;
