import React from 'react';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { lazy } from '@atlassian/react-loosely-lazy';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const LazyCmdbUpsellPage = lazy(() =>
	import(/* webpackChunkName: "cmdb-upsell-page" */ './ui').then((module) => module.UpsellPage),
);

export const AsyncCmdbUpsellPage = () => (
	<Placeholder name="cmdb-upsell-page" fallback={null}>
		<LazyCmdbUpsellPage />
	</Placeholder>
);
