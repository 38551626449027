import type { WorkspaceId } from '@atlassian/jira-servicedesk-insight-shared-types/src/common/types/shared-types/index.tsx';
import { getCmdbRestBaseUrl } from './index.tsx';

/**
 * /gateway/api/jsm/assets/workspace/${workspaceId}/v1/data-manager/token/${type}?label=${label}&duration=${duration}
 */
export const getDataManagerGenerateContainerTokenUrl = (
	workspaceId: WorkspaceId,
	label: string,
	type: string,
	duration: string,
) =>
	`${getCmdbRestBaseUrl(workspaceId)}/data-manager/token/${type}?label=${label}&duration=${duration}`;

/**
 * /gateway/api/jsm/assets/workspace/${workspaceId}/v1/data-manager/token/${tokenId}
 */
export const delDataManagerContainerTokenUrl = (workspaceId: WorkspaceId, tokenID: string) =>
	`${getCmdbRestBaseUrl(workspaceId)}/data-manager/token/${tokenID}`;

/**
 * /gateway/api/jsm/assets/workspace/${workspaceId}/v1/data-manager/token
 */
export const getDataManagerFetchContainerTokenUrl = (workspaceId: WorkspaceId) =>
	`${getCmdbRestBaseUrl(workspaceId)}/data-manager/token`;

/**
 * /gateway/api/jsm/data-manager/workspace/${workspaceId}/app/config/sync/provisioningstatus
 */
export const getDataManagerProvisioningStatusUrl = (workspaceId: WorkspaceId) =>
	`${getAirtrackAppRestBaseUrl(workspaceId)}/config/sync/provisioningstatus`;

/**
 * /gateway/api/jsm/data-manager/workspace/${workspaceId}/app/config/featuregate/${featureFlagName}
 */
export const getDataManagerFeatureFlagStatusByName = (
	workspaceId: WorkspaceId,
	featureFlagName: string,
) => `${getAirtrackAppRestBaseUrl(workspaceId)}/config/featuregate/${featureFlagName}`;

const getAirtrackAppRestBaseUrl = (workspaceId: WorkspaceId) =>
	`/gateway/api/jsm/data-manager/workspace/${workspaceId}/app`;
