import React from 'react';
import { styled } from '@compiled/react';
import Button from '@atlaskit/button';
import { Box, xcss } from '@atlaskit/primitives';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import type { Item } from '../../../common/types.tsx';
import { InsightRapidSearchDropdownOption } from './dropdown-option/index.tsx';
import { messages } from './messages.tsx';

export type Props = {
	canShowMore: boolean;
	groupTitle: string;
	items: Item[];
	emptyMessage: string;
	onShowMore: () => void;
};

export const DropdownContainer = ({
	groupTitle,
	items,
	emptyMessage,
	canShowMore,
	onShowMore,
}: Props) => {
	const { formatMessage } = useIntl();

	return (
		<div>
			<Title>{groupTitle}</Title>
			{items.length === 0 ? (
				<Box xcss={emptyMessageStyles}>{emptyMessage}</Box>
			) : (
				<OptionsList>
					{items.map((item) => (
						<InsightRapidSearchDropdownOption key={item.id} {...item} />
					))}
				</OptionsList>
			)}
			{canShowMore && (
				<Box xcss={listFooterStyles}>
					<Button
						appearance="link"
						spacing="none"
						onClick={onShowMore}
						/* @ts-expect-error - TS2322 - Type 'string' is not assignable to type 'number | undefined'. */
						tabIndex="-1"
						testId="servicedesk-insight-rapid-search-bar.ui.popup-content.dropdown-container.show-more-button"
					>
						{formatMessage(messages.showMore)}
					</Button>
				</Box>
			)}
		</div>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Title = styled.h3({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text.subtle', colors.N200),
	font: token('font.body.UNSAFE_small'),
	textTransform: 'uppercase',
	padding: token('space.100', '8px'),
	fontWeight: 600,
});

const emptyMessageStyles = xcss({
	padding: 'space.100',
});

const listFooterStyles = xcss({
	padding: 'space.100',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const OptionsList = styled.ul({
	padding: 0,
	marginTop: 0,
	listStyleType: 'none',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'> li': {
		marginTop: 0,
	},
});
