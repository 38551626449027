import React, { type ReactNode } from 'react';
import type { CreateUIAnalyticsEvent } from '@atlaskit/analytics-next';
import { useWorkspaceContext } from '@atlassian/jira-servicedesk-insight-common-workspace-context/src/controllers/workspace-context/index.tsx';
import {
	createStore,
	createContainer,
	createActionsHook,
	createStateHook,
} from '@atlassian/react-sweet-state';
import { initialState } from '../constants.tsx';
import type { State, ContainerProps, ActionApi } from '../types.tsx';
import { getObjectUsageInfo } from './actions/get-object-usage-info/index.tsx';
import { actions, type Actions } from './actions/index.tsx';

const Store = createStore({
	initialState,
	actions,
});

const useActions = createActionsHook(Store);

export const useObjectUsageDismiss = (): (() => void) => {
	const { dismissMessage } = useActions();
	return dismissMessage;
};

export const useObjectUsageRefreshObjectCount = (): (({
	createAnalyticsEvent,
}: {
	createAnalyticsEvent?: CreateUIAnalyticsEvent;
}) => void) => {
	const { refreshObjectCount } = useActions();
	return (createAnalyticsEvent) => refreshObjectCount(createAnalyticsEvent);
};

export const useObjectUsageInfo = createStateHook(Store, {
	selector: getObjectUsageInfo,
});

export const useBillingPeriod = createStateHook(Store, {
	selector: ({ billingPeriod }) => billingPeriod,
});

export const useLimitState = createStateHook(Store, {
	selector: ({ limitState }) => limitState,
});

const dispatchInitialState =
	(): ActionApi =>
	(
		{ dispatch },
		{ isJiraAdmin, objectCount, objectLimit, billingPeriod, limitState, workspaceId },
	) => {
		dispatch(
			actions.setInitialData({
				isJiraAdmin,
				objectCount,
				objectLimit,
				billingPeriod,
				limitState,
				workspaceId,
			}),
		);
	};

const ObjectUsageStoreContainer = createContainer<State, Actions, ContainerProps>(Store, {
	onInit: dispatchInitialState,
	onUpdate: dispatchInitialState,
	displayName: 'JsmCmdbObjectUsageStoreContainer',
});

export const ObjectUsageProvider = ({
	children,
	isJiraAdmin,
	objectCount,
	objectLimit,
	billingPeriod,
	limitState,
}: { children: ReactNode } & Omit<ContainerProps, 'workspaceId'>) => {
	const { workspaceId } = useWorkspaceContext();

	if (!workspaceId) {
		throw new Error('workspaceId not defined');
	}

	return (
		<ObjectUsageStoreContainer
			isJiraAdmin={isJiraAdmin}
			objectCount={objectCount}
			objectLimit={objectLimit}
			billingPeriod={billingPeriod}
			limitState={limitState}
			workspaceId={workspaceId}
			isGlobal
		>
			{children}
		</ObjectUsageStoreContainer>
	);
};
