import { UFOExperience, ExperienceTypes, ExperiencePerformanceTypes } from '@atlassian/ufo';

export const graphModalExperience = new UFOExperience(
	'service-management.insight-cmdb.display-graph-modal',
	{
		performanceType: ExperiencePerformanceTypes.InlineResult,
		type: ExperienceTypes.Experience,
	},
);

export const rapidSearchExperience = new UFOExperience(
	'service-management.insight-cmdb.display-rapid-search-results',
	{
		performanceType: ExperiencePerformanceTypes.InlineResult,
		type: ExperienceTypes.Experience,
	},
);

export const autocompleteInsightFieldExperience = new UFOExperience(
	'service-management.insight-cmdb.autocomplete-search-insight-field',
	{
		performanceType: ExperiencePerformanceTypes.InlineResult,
		type: ExperienceTypes.Experience,
		featureFlags: ['jsm-insight-field-performance-improvements_fplgq'],
	},
);

export const fetchAffectedServicesExperience = new UFOExperience(
	'service-management.insight-cmdb.fetch-affected-services',
	{
		performanceType: ExperiencePerformanceTypes.InlineResult,
		type: ExperienceTypes.Experience,
	},
);

export const objectDetailsViewExperience = new UFOExperience('jsm-cmdb-object-details-view', {
	type: ExperienceTypes.Experience,
	performanceType: ExperiencePerformanceTypes.PageSegmentLoad,
});

export const objectViewInlineEditSwitchFromReadToEditModeExperience = new UFOExperience(
	'service-management.insight-cmdb.object-view-inline-edit.switch-from-read-to-edit-mode',
	{
		performanceType: ExperiencePerformanceTypes.InlineResult,
		type: ExperienceTypes.Experience,
	},
);

export const objectViewInlineEditSwitchFromEditToReadModeExperience = new UFOExperience(
	'service-management.insight-cmdb.object-view-inline-edit.switch-from-edit-to-read-mode',
	{
		performanceType: ExperiencePerformanceTypes.InlineResult,
		type: ExperienceTypes.Experience,
	},
);

/**
 * Measures time from "Create" button clicked to the new object type being rendered in the list.
 */
export const createObjectTypeExperience = new UFOExperience('jsm-cmdb-create-object-type', {
	type: ExperienceTypes.Experience,
	performanceType: ExperiencePerformanceTypes.Custom,
	timings: [
		{
			key: 'created-on-server',
			endMark: 'object-type-created-on-server',
		},
	],
});

export const createObjectExperience = new UFOExperience(
	'service-management.insight-cmdb.create-object',
	{
		performanceType: ExperiencePerformanceTypes.InlineResult,
		type: ExperienceTypes.Experience,
	},
);

export const loadSchemaObjectListExperience = new UFOExperience('jsm-cmdb-load-object-list', {
	type: ExperienceTypes.Experience,
	performanceType: ExperiencePerformanceTypes.PageSegmentLoad,
});

export const loadAttributesConfigurationExperience = new UFOExperience(
	'jsm-cmdb-load-attributes-tab',
	{
		type: ExperienceTypes.Experience,
		performanceType: ExperiencePerformanceTypes.PageSegmentLoad,
	},
);
export const updateObjectFilterExperience = new UFOExperience('jsm-cmdb-object-filter', {
	type: ExperienceTypes.Experience,
	performanceType: ExperiencePerformanceTypes.Custom,
	timings: [
		{
			key: 'response-received',
			endMark: 'server-response-received',
		},
		{
			key: 'object-table-rendered',
			endMark: 'object-table-rendered',
		},
		{
			key: 'object-list-rendered',
			endMark: 'object-list-rendered',
		},
		{
			key: 'no-filter-results-rendered',
			endMark: 'no-filter-results-rendered',
		},
	],
});

export const getUpdateGlobalConfigInlineEditExperience = () =>
	new UFOExperience('service-management.insight-cmdb.global-config-inline-edit.update', {
		performanceType: ExperiencePerformanceTypes.InlineResult,
		type: ExperienceTypes.Experience,
	});

export const loadObjectDetailExperience = new UFOExperience(
	'service-management.insight-cmdb.embedded-object-details-view',
	{
		type: ExperienceTypes.Experience,
		performanceType: ExperiencePerformanceTypes.PageSegmentLoad,
	},
);
