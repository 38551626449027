import React, { type ReactNode } from 'react';
import { Redirect, usePathParam } from '@atlassian/react-resource-router';
import { useServiceDeskContext } from '../../../servicedesk-context/index.tsx';
import { PROJECT_PATH } from '../../constants.tsx';
import { isAccessError } from '../../utils.tsx';

type Props = {
	children: ReactNode;
};

const AgentCheck = ({ children }: Props) => {
	const [projectKey = ''] = usePathParam('projectKey');
	const [{ data, error }] = useServiceDeskContext();

	if (data || error) {
		// @ts-expect-error - TS2345 - Argument of type 'Error | Record<string, any> | (Error & Record<string, any>) | (Record<string, any> & Error) | null' is not assignable to parameter of type '{ statusCode?: number | undefined; } | null'.
		if (!isAccessError(error)) {
			const { userRole = '' } = data || {};

			if (userRole === 'agent' || userRole === 'agent-project-admin') {
				return <>{children}</>;
			}

			if (error) {
				throw error;
			}
		}

		return <Redirect to={`${PROJECT_PATH}/${projectKey}/issues`} />;
	}

	return null;
};

export default AgentCheck;
