import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';

export const cmdbDataManagerChangeBoardingEntryPoint = createEntryPoint({
	root: JSResourceForInteraction(
		() => import(/* webpackChunkName: "async-cmdb-data-manager-change-boarding" */ './src'),
	),
	getPreloadProps: () => ({
		queries: {},
	}),
});
