import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	warningIcon: {
		id: 'servicedesk-cmdb-async-actions.common.flags.completed-flag-description.warning-icon',
		defaultMessage: 'Warning',
		description: 'Label for warning icon',
	},
	errorsEncountered: {
		id: 'servicedesk-cmdb-async-actions.common.flags.completed-flag-description.errors-encountered',
		defaultMessage: `{errorCount, plural,
    		=0 {Error encountered}
    		one {{errorCount} error encountered}
    		other {{errorCount} errors encountered}
		}`,
		description: 'Message displayed when an error occurred for an async action',
	},
});
