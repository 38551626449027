import { createIllustration } from '@atlassian/jira-illustration/src/ui/index.tsx';
import type { SVGViewBox } from '@atlassian/jira-illustration/src/ui/types.tsx';
// eslint-disable-next-line jira/import/no-duplicates, import/no-duplicates
import srcDark from '../../assets/error-white.svg';
// eslint-disable-next-line import/no-duplicates
import srcLight from '../../assets/error-white.svg';

const Illustration = createIllustration({
	srcLight,
	srcDark,
});

export default Illustration;

const viewBox: SVGViewBox = [0, 0, 164, 212];
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports, @atlassian/eng-health/no-barrel-files/disallow-reexports
export { srcLight, srcDark, viewBox };
