import type { ObjectSchema } from '@atlassian/jira-servicedesk-insight-shared-types/src/common/types/object-schema.tsx';
import type { StoreActionApi } from '@atlassian/react-sweet-state';
import type { ActionApi, State } from '../../../../common/types.tsx';
import { getSchemaStateOrThrow } from '../../../../common/utils.tsx';
import { fetchSchemas } from '../fetch-schemas/index.tsx';

export const sortObjectSchemasList = (objectSchemas: ObjectSchema[]): ObjectSchema[] =>
	objectSchemas.sort((a, b) => a.name.localeCompare(b.name));

const clientSideUpdate = (
	createdSchema: ObjectSchema,
	{ getState, setState }: StoreActionApi<State>,
) => {
	const originalState = getSchemaStateOrThrow(getState());
	const updatedSchemas = sortObjectSchemasList(originalState.schemas.concat([createdSchema]));

	setState({
		schemaState: {
			...originalState,
			schemas: updatedSchemas,
		},
	});
};

const serverSideRefresh = ({ dispatch }: StoreActionApi<State>) => {
	dispatch(fetchSchemas());
};

export const insertCreatedSchema =
	(createdSchema: ObjectSchema): ActionApi =>
	async (storeActionApi) => {
		clientSideUpdate(createdSchema, storeActionApi);
		serverSideRefresh(storeActionApi);
	};
