import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	objects: {
		id: 'servicedesk-insight-landing-page.usage-panel.objects',
		defaultMessage: 'Objects',
		description: 'Title for the the panel showing the number of objects',
	},
	excludedSchemasInfo: {
		id: 'servicedesk-insight-landing-page.usage-panel.excluded-schemas-info',
		defaultMessage: 'Excludes objects stored within system schemas.',
		description:
			'Info text describing that objects stored within system schemas are excluded from the statistics',
	},
	assetsUsageInfo: {
		id: 'servicedesk-insight-landing-page.usage-panel.assets-usage-info',
		defaultMessage: 'Object usage information',
		description: 'Help text for a popup that explains the usage information',
	},
	viewDetailedUsage: {
		id: 'servicedesk-insight-landing-page.usage-panel.view-detailed-usage',
		defaultMessage: 'View detailed usage',
		description: 'Link to the detailed usage page',
	},
	calculationInfo: {
		id: 'servicedesk-insight-landing-page.usage-panel.calculation-info',
		defaultMessage: 'Read how Assets usage is calculated.',
		description: 'Link to info about how usage is calculated',
	},
});
