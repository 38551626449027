import {
	createContainer,
	createHook,
	createStore,
	createActionsHook,
} from '@atlassian/react-sweet-state';
import { actions, type Actions } from './actions/index.tsx';
import { cancelPolling } from './handlers/cancel-polling/index.tsx';
import { fetchInitialTasks } from './handlers/fetch-initial-tasks/index.tsx';
import type { State, ContainerProps } from './types.tsx';

export const initialState: State = {
	isPolling: false,
	// Stores reference to the reject function of the polling promise so we can cancel it externally
	pollingReject: undefined,
	pollingTimeoutIds: undefined,
	tasks: undefined,
	bulkDelete: {
		isSubmitting: false,
	},
	bulkEdit: {
		isSubmitting: false,
	},
	bulkExport: {
		isSubmitting: false,
	},
	bulkQrCode: {
		isSubmitting: false,
	},
	isDismissing: [],
};

const store = createStore({
	initialState,
	actions,
});

export const CmdbAsyncActionsStoreContainer = createContainer<State, Actions, ContainerProps>(
	store,
	{
		onInit: fetchInitialTasks,
		onCleanup: cancelPolling,
	},
);

export const useCmdbAsyncActionsStore = createHook(store);

export const useCmdbAsyncBulkDelete = createHook(store, {
	selector: ({ bulkDelete }) => bulkDelete,
});

export const useCmdbAsyncBulkEdit = createHook(store, {
	selector: ({ bulkEdit }) => bulkEdit,
});

export const useCmdbAsyncBulkExport = createHook(store, {
	selector: ({ bulkExport }) => bulkExport,
});

export const useCmdbAsyncBulkQrCode = createHook(store, {
	selector: ({ bulkQrCode }) => bulkQrCode,
});

export const useCmdbAsyncActions = createActionsHook(store);
