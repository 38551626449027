import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { fireTrackAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { toWorkspaceId } from '@atlassian/jira-servicedesk-insight-shared-types/src/common/types/shared-types/index.tsx';
import {
	type NewIconType,
	type ActionApi,
	type InsightMediaAuthenticationUpload,
	type InsightMediaAuthenticationFetch,
	type MediaIconUploadResult,
	MEDIA_PROCESS_STATUS,
	type IconType,
	toMediaBaseUrl,
	toCollectionId,
	toFileName,
	toMediaIssuer,
	toMediaJwt,
	toMediaFileUuid,
	toIconName,
	toMediaClientId,
} from '../../common/types.tsx';
import {
	mediaInfoForUpload,
	mediaUploadFile,
	mediaInfoForPulling,
	mediaGetFileInfo,
	publishNewIcon,
} from './ajax-requests.tsx';

export const addIcon =
	(newIcon: NewIconType): ActionApi =>
	async ({ setState, getState }, { workspaceId, createAnalyticsEvent }) => {
		const state = getState();
		setState({
			...state,
			icons: { ...state.icons, addLoading: true },
		});

		try {
			const uploadCredentials: InsightMediaAuthenticationUpload = await mediaInfoForUpload(
				toWorkspaceId(workspaceId),
			);

			const uploadMediaResult: MediaIconUploadResult = await mediaUploadFile({
				mediaBaseUrl: toMediaBaseUrl(uploadCredentials.mediaBaseUrl),
				collectionId: toCollectionId(uploadCredentials.collectionId),
				fileName: toFileName(newIcon.fileName),
				// Remove when cleaning up assets_rearch_media_client_auth
				issuer: toMediaIssuer(uploadCredentials.issuer),
				clientId: toMediaClientId(uploadCredentials.clientId),
				mediaJwtToken: toMediaJwt(uploadCredentials.mediaJwtToken),
				blob: newIcon.blob,
			});

			const mediaInfoForPullingResult: InsightMediaAuthenticationFetch = await mediaInfoForPulling(
				toWorkspaceId(workspaceId),
				toMediaFileUuid(uploadMediaResult.data.id),
			);

			const pollMediaFileReady = () => {
				const getMediaFileStatus = () =>
					mediaGetFileInfo({
						mediaBaseUrl: toMediaBaseUrl(mediaInfoForPullingResult.mediaBaseUrl),
						mediaFileUuid: toMediaFileUuid(uploadMediaResult.data.id),
						// Remove when cleaning up assets_rearch_media_client_auth
						issuer: toMediaIssuer(mediaInfoForPullingResult.issuer),
						clientId: toMediaClientId(uploadCredentials.clientId),
						mediaJwtToken: toMediaJwt(mediaInfoForPullingResult.mediaJwtToken),
					});

				return new Promise(
					(
						resolve: (result: Promise<undefined> | undefined) => void,
						// eslint-disable-next-line @typescript-eslint/no-explicit-any
						reject: (error?: any) => void,
					) => {
						const poll = (attempt = 0) => {
							const statusPromise = getMediaFileStatus();
							statusPromise
								.then((response) => {
									const status = response.data.processingStatus;
									switch (status) {
										default:
										case MEDIA_PROCESS_STATUS.PENDING:
										case MEDIA_PROCESS_STATUS.RUNNING:
											return setTimeout(() => {
												// 20 tries before throwing an error
												if (attempt > 20) {
													return reject(new Error('Failed to add icon after 10 tries'));
												}
												return poll(attempt + 1);
											}, 5000);
										case MEDIA_PROCESS_STATUS.SUCCEEDED:
											// @ts-expect-error - TS2794 - Expected 1 arguments, but got 0. Did you forget to include 'void' in your type argument to 'Promise'?
											return resolve();
										case MEDIA_PROCESS_STATUS.FAILED:
											return reject(new Error('Media services failed to process file!'));
									}
								})
								.catch((error) => reject(new Error(error)));
						};
						poll();
					},
				);
			};
			await pollMediaFileReady();

			const savedIconEntry: IconType = await publishNewIcon(
				toWorkspaceId(workspaceId),
				toIconName(newIcon.iconName),
				toMediaFileUuid(uploadMediaResult.data.id),
			);

			// Make flow happy
			if (!Array.isArray(state.icons.data))
				throw new Error('Invalid state! Icons data is not array!');

			const newIconsData = state.icons.data.slice(0);
			newIconsData.unshift(savedIconEntry);
			const newState = {
				...state,
				icons: {
					...state.icons,
					addLoading: false,
					data: newIconsData,
				},
			};
			setState(newState);
			fireTrackAnalytics(createAnalyticsEvent({}), 'addIcon succeeded');
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			setState({
				...state,
				icons: { ...state.icons, addLoading: false, addError: error },
			});
			fireErrorAnalytics({
				meta: {
					id: 'addIcon',
					packageName: 'jiraServicedeskInsightGlobalConfigurationStore',
					teamName: 'falcons',
				},
				error,
			});

			throw error;
		}
	};
