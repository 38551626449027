// THIS FILE WAS AUTO-GENERATED. MODIFY WITH CAUTION. See README.md

import { createIllustration } from '@atlassian/jira-illustration/src/ui/index.tsx';
import type { SVGViewBox } from '@atlassian/jira-illustration/src/ui/types.tsx';
import srcDark from '../../assets/empty-no-search-results-v2-darkmode.svg';
import srcLight from '../../assets/empty-no-search-results-v2.svg';

/** @deprecated cf. https://hello.atlassian.net/wiki/spaces/IMKT/pages/743859674/Empty+state+and+Error+state+guidelines */
const Illustration = createIllustration({
	srcLight,
	srcDark,
});

export default Illustration;

// for advanced use only
const viewBox: SVGViewBox = [0, 0, 205, 199];
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports, @atlassian/eng-health/no-barrel-files/disallow-reexports
export { srcLight, srcDark, viewBox };
