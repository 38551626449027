/* eslint-disable @typescript-eslint/no-explicit-any */
import type { IntercomWindow } from './types.tsx';

/**
 * https://www.intercom.com/help/en/articles/170-integrate-intercom-in-a-single-page-app
 * This function is direct copied from offical integration guide from intercom and converted to typescript manually.
 * It handles the initialisation for intercom.
 */
export const intercomScript = (appId: string) => {
	const w: IntercomWindow = window;
	const ic = w.Intercom;

	if (typeof ic === 'function') {
		ic('reattach_activator');
		ic('update', w.intercomSettings);
	} else {
		const d: Document = document;

		const i = (...args: Array<any>): void => {
			i.c(args);
		};

		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		i.q = [] as Array<any>;
		i.c = (args: Array<any>): void => {
			i.q.push(args);
		};
		w.Intercom = i;
		const l = (): void => {
			const s: HTMLScriptElement = d.createElement('script');
			s.type = 'text/javascript';
			s.async = true;
			s.src = `https://widget.intercom.io/widget/${appId}`;

			const x: HTMLScriptElement | undefined = d.getElementsByTagName('script')[0];

			x?.parentNode?.insertBefore(s, x);
		};

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		if (document.readyState === 'complete') {
			l();
		} else if (w.attachEvent) {
			w.attachEvent('onload', l);
		} else {
			w.addEventListener('load', l, false);
		}
	}
};
