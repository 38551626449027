export const DATA_MANAGER_ADMIN = 'ari:cloud:cmdb::role/airtrack/admin' as const;
export const DATA_MANAGER_REPORT_VIEWER = 'ari:cloud:cmdb::role/airtrack/report-viewer' as const;
export const DATA_MANAGER_REPORT_EDITOR = 'ari:cloud:cmdb::role/airtrack/report-editor' as const;
export const DATA_MANAGER_ADAPTERS_ADMIN = 'ari:cloud:cmdb::role/airtrack-buoy/admin' as const;

export const DATA_MANAGER_ROLES: string[] = [
	DATA_MANAGER_ADMIN,
	DATA_MANAGER_REPORT_VIEWER,
	DATA_MANAGER_REPORT_EDITOR,
];

export const DATA_MANAGER_ADAPTERS_ROLES: string[] = [DATA_MANAGER_ADAPTERS_ADMIN];

export const ORDERED_ASSETS_DATA_MANAGER_ROLES = [
	DATA_MANAGER_ADMIN,
	DATA_MANAGER_ADAPTERS_ADMIN,
	DATA_MANAGER_REPORT_EDITOR,
	DATA_MANAGER_REPORT_VIEWER,
];
