import type { TaskOperationType } from '@atlassian/jira-servicedesk-insight-shared-types/src/common/types/task.tsx';
import { matchTaskOperationType } from '@atlassian/jira-servicedesk-insight-shared-types/src/common/utils/task-type-matchers/index.tsx';
import messages from '../../../common/messages.tsx';
import type { CmdbAsyncActionStoreAction } from '../../types.tsx';

type Props = {
	objectCount: string;
	action: TaskOperationType;
};

export const showErrorFlag =
	({ objectCount, action }: Props): CmdbAsyncActionStoreAction =>
	async (_, { showFlag, formatMessage }) => {
		showFlag({
			type: 'error',
			title: formatMessage(
				matchTaskOperationType(action, {
					BulkExport: () => messages.submitErrorFlagTitleBulkExport,
					QrCode: () => messages.submitErrorFlagTitleBulkQrCode,
					BulkEdit: () => messages.submitErrorFlagTitleBulkEdit,
					BulkDelete: () => messages.submitErrorFlagTitleBulkDelete,
				}),
				{
					objectCount,
				},
			),
			description: formatMessage(messages.submitErrorFlagDescription),
		});
	};
