import React, { type ReactNode } from 'react';
import { ProjectNotFound, GenericError } from '../../errors/index.tsx';
import { useServiceDeskContext } from '../../servicedesk-context/index.tsx';
import { isAccessError } from '../utils.tsx';

type Props = {
	children: ReactNode;
};

const SettingsAccessCheck = ({ children }: Props) => {
	const [{ data: serviceDeskContext, error: serviceDeskContextError }] = useServiceDeskContext();

	const userRole = serviceDeskContext?.userRole;
	// @ts-expect-error - TS2345 - Argument of type 'Error | Record<string, any> | (Error & Record<string, any>) | (Record<string, any> & Error) | null' is not assignable to parameter of type '{ statusCode?: number | undefined; } | null'.
	const hasAccessError = isAccessError(serviceDeskContextError);

	// Service desk context is updated on every route change
	// If context fails to give us an indicative result (e.g. fetch failed, 500 error)
	// We will continue to use current value to assess permissions
	if (userRole === 'agent-project-admin' && !hasAccessError) {
		return <>{children}</>;
	}

	if (userRole !== undefined || hasAccessError) {
		return <ProjectNotFound />;
	}

	if (serviceDeskContextError) {
		return <GenericError />;
	}

	return null;
};

export default SettingsAccessCheck;
