import React from 'react';
import RefreshIcon from '@atlaskit/icon/glyph/refresh';
import type { TaskOperationType } from '@atlassian/jira-servicedesk-insight-shared-types/src/common/types/task.tsx';
import { matchTaskOperationType } from '@atlassian/jira-servicedesk-insight-shared-types/src/common/utils/task-type-matchers/index.tsx';
import messages from '../../../common/messages.tsx';
import type { CmdbAsyncActionStoreAction } from '../../types.tsx';

type Props = {
	id: string;
	objectCount: string;
	action: TaskOperationType;
};

export const showSuccessFlag =
	({ id, objectCount, action }: Props): CmdbAsyncActionStoreAction =>
	async (_, { showFlag, formatMessage }) => {
		showFlag({
			id,
			icon: <RefreshIcon label="" />,
			type: 'info',
			title: formatMessage(
				matchTaskOperationType(action, {
					BulkExport: () => messages.submitSuccessFlagTitleBulkExport,
					QrCode: () => messages.submitSuccessFlagTitleBulkQrCode,
					BulkEdit: () => messages.submitSuccessFlagTitleBulkEdit,
					BulkDelete: () => messages.submitSuccessFlagTitleBulkDelete,
				}),
				{
					objectCount,
				},
			),
			description: formatMessage(messages.submitSuccessFlagDescription),
		});
	};
