import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { performGetRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import { fireTrackAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { getCmdbObjectRecentUrl } from '@atlassian/jira-servicedesk-cmdb-rest-urls/src/common/constants/object.tsx';
import type { ReferencedObjectReadViewType } from '@atlassian/jira-servicedesk-insight-attribute-views/src/ui/referenced-object/index.tsx';
import type { ActionApi } from '../../../../common/types.tsx';

type RecentObjectsResponse = {
	readonly startAt: number;
	readonly maxResults: number;
	readonly total: number;
	readonly isLast: boolean;
	values: ReferencedObjectReadViewType[];
};

export const maxResults = 20;

export const fetchRecentObjects =
	(): ActionApi =>
	async ({ setState }, { workspaceId, createAnalyticsEvent }) => {
		try {
			const response: RecentObjectsResponse = await performGetRequest(
				getCmdbObjectRecentUrl(workspaceId, { maxResults }),
			);

			setState({
				recentObjectState: {
					type: 'success',
					recentObjects: response.values,
				},
			});

			fireTrackAnalytics(createAnalyticsEvent({}), 'fetchRecentObjects succeeded');
		} catch (error) {
			const typesafeError = error instanceof Error ? error : new Error('Unknown error');
			setState({
				recentObjectState: { type: 'error', error: typesafeError },
			});
			fireErrorAnalytics({
				error: typesafeError,
				meta: {
					id: 'fetchRecentObjects',
					packageName: 'jiraServicedeskInsightLandingPageStore',
					teamName: 'falcons',
				},
			});
		}
	};
