import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	errorTitle: {
		id: 'servicedesk-insight-rapid-search-bar.popup-content.error.error-title',
		defaultMessage: 'We can’t show your results right now',
		description:
			'Error message instructing user that the rapid search result did not load successfully',
	},
	errorDescription: {
		id: 'servicedesk-insight-rapid-search-bar.popup-content.error.error-description',
		defaultMessage: 'Give it a minute and try again.',
		description:
			'Error description instructing user to refresh the page to try again after an error occurred.',
	},
});
