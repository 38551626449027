import type { State, SuccessSchemaState } from './types.tsx';

export const getSchemaStateOrThrow = (state: Readonly<State>): SuccessSchemaState => {
	const { schemaState } = state;
	if (schemaState.type !== 'success') {
		throw new Error(`Unexpected schemaState.type value: ${schemaState.type}`);
	} else {
		return schemaState;
	}
};
