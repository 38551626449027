import React, { type ReactNode, type MouseEvent } from 'react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import Button from '@atlaskit/button';
import EditorSettingsIcon from '@atlaskit/icon/glyph/editor/settings';
import { Box, xcss } from '@atlaskit/primitives';
import { Link } from '@atlassian/react-resource-router';

export type ConfigLinkProps = {
	to: string;
	testId?: string;
	children: ReactNode;
	onClick?: (analyticsEvent: UIAnalyticsEvent) => void;
};

export const ConfigLink = ({ children, onClick, testId, to }: ConfigLinkProps) => (
	<Box xcss={wrapperStyles}>
		<Button
			href={to}
			component={Link}
			appearance="subtle"
			iconBefore={<EditorSettingsIcon label="" size="medium" />}
			testId={testId}
			onClick={(event: MouseEvent<HTMLElement>, analyticsEvent: UIAnalyticsEvent) => {
				onClick?.(analyticsEvent);
			}}
		>
			{children}
		</Button>
	</Box>
);

const wrapperStyles = xcss({
	marginLeft: 'space.100',
});
