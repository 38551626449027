import React, { type ComponentType } from 'react';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { lazy } from '@atlassian/react-loosely-lazy';

// eslint-disable-next-line @typescript-eslint/no-explicit-any, jira/deprecations/no-rll-client-async-experiences
const InsightLandingPageChangeboarding: ComponentType<Record<any, any>> = lazy(
	() => import(/* webpackChunkName: "jsm-insight-landing-page-changeboarding-check" */ './index'),
);

export const AsyncInsightLandingPageChangeboarding = () => (
	<Placeholder name="jsm-insight-landing-page-changeboarding-check" fallback={null}>
		<InsightLandingPageChangeboarding />
	</Placeholder>
);
