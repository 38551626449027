import React, { useState } from 'react';
import { styled } from '@compiled/react';
import Button from '@atlaskit/button';
import Heading from '@atlaskit/heading';
import ShortcutIcon from '@atlaskit/icon/glyph/shortcut';
import InformationIcon from '@atlaskit/icon/utility/migration/information--editor-panel';
import Popup from '@atlaskit/popup';
import { Box, xcss, Inline, Text } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import HelpPanelLink from '@atlassian/jira-help-panel-button/src/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { useInsightGlobalConfig } from '@atlassian/jira-servicedesk-insight-global-configuration-store/src/services/index.tsx';
import { getFeatureUsagePageUrl } from '@atlassian/jira-servicedesk-insight-urls/src/index.tsx';
import { messages } from './messages.tsx';

export const UsagePanel = () => {
	const { formatMessage, formatNumber } = useIntl();
	const [{ config }] = useInsightGlobalConfig();
	const [isHelpOpen, setIsHelpOpen] = useState(false);
	if (!config) {
		return null;
	}
	const { insightAdministrator, limitInfo } = config;
	const { objectCount } = limitInfo;

	const calculationInfo = formatMessage(messages.calculationInfo);
	const renderHelpContent = () => (
		<Box padding="space.200" xcss={helpBoxStyles}>
			<p>{formatMessage(messages.excludedSchemasInfo)}</p>
			<HelpPanelLink
				articleId="3sbbZSMOEhRWrOLZI4UNNs"
				appearance="link"
				spacing="none"
				onClick={() => setIsHelpOpen(false)}
				fallbackComponent={
					<a
						href="https://support.atlassian.com/jira-service-management-cloud/docs/what-is-feature-usage-and-how-it-works"
						target="_blank"
						rel="noopener noreferrer"
					>
						{calculationInfo}
					</a>
				}
			>
				<HelpText>{calculationInfo}</HelpText>
			</HelpPanelLink>
		</Box>
	);
	return (
		<Container>
			<Inline space="space.050" alignBlock="center">
				<Heading as="h2" size="xsmall">
					{formatMessage(messages.objects)}{' '}
				</Heading>
				<Popup
					isOpen={isHelpOpen}
					onClose={() => setIsHelpOpen(false)}
					placement="top-start"
					content={renderHelpContent}
					trigger={(triggerProps) => (
						<Button
							{...triggerProps}
							appearance="subtle"
							spacing="none"
							iconBefore={
								<InformationIcon
									label={formatMessage(messages.assetsUsageInfo)}
									spacing="spacious"
									color={isHelpOpen ? token('color.icon.selected') : token('color.icon.subtle')}
								/>
							}
							isSelected={isHelpOpen}
							onClick={() => setIsHelpOpen(!isHelpOpen)}
						/>
					)}
				/>
			</Inline>
			<Heading size="large">{formatNumber(objectCount)}</Heading>
			{insightAdministrator && (
				<div>
					<a href={getFeatureUsagePageUrl()} target="_blank" rel="noopener noreferrer">
						<Inline space="space.050" alignBlock="center">
							<Text color="inherit">{formatMessage(messages.viewDetailedUsage)}</Text>
							<ShortcutIcon size="small" label="" />
						</Inline>
					</a>
				</div>
			)}
		</Container>
	);
};

const helpBoxStyles = xcss({
	maxWidth: '210px',
});
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const HelpText = styled.div({
	whiteSpace: 'normal',
	textAlign: 'start',
});
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	display: 'flex',
	gap: token('space.150', '12px'),
	flexDirection: 'column',
	marginTop: token('space.200', '16px'),
	marginBottom: token('space.300', '24px'),
});
