import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { fireTrackAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { STATUS_COMPLETED } from '@atlassian/jira-servicedesk-insight-shared-types/src/common/types/task.tsx';
import type { StoreActionApi } from '@atlassian/react-sweet-state/src/types';
import { POLLING_DELAY } from '../../../common/constants.tsx';
import { ConsecutivePollingError } from '../../../common/errors.tsx';
import { getCompletedTasks } from '../../../common/utils/get-completed-tasks/index.tsx';
import { mergeTasks } from '../../../common/utils/merge-tasks/index.tsx';
import { getAllTasks } from '../../services/get-all-tasks/index.tsx';
import type { CmdbAsyncActionStoreAction, ContainerProps, State } from '../../types.tsx';
import { showCompletedFlags } from '../show-completed-flags/index.tsx';

export type PollTasks = {
	consecutiveErrors: number;
	resolve: (value?: unknown) => void;
	reject: (error: Error) => void;
} & StoreActionApi<State> &
	Pick<ContainerProps, 'workspaceId' | 'createAnalyticsEvent'>;

/*
  We have chosen these numbers as it would indicate that the polling has failed
  for 1 minute which is a reasonable time to stop polling.
*/
export const MAX_ERRORS = 6;

export const startTaskPolling =
	(): CmdbAsyncActionStoreAction =>
	async ({ getState, setState, dispatch }, { workspaceId, createAnalyticsEvent }) => {
		try {
			if (getState().isPolling) {
				return;
			}
			setState({ isPolling: true });

			await new Promise((resolve, reject) => {
				// Store the reject function to be able to call it later to cancel polling
				setState({ pollingReject: reject });
				pollTasks({
					consecutiveErrors: 0,
					getState,
					setState,
					dispatch,
					workspaceId,
					createAnalyticsEvent,
					resolve,
					reject,
				});
			});
		} catch (error) {
			if (error instanceof ConsecutivePollingError) {
				fireErrorAnalytics({
					error: error instanceof Error ? error : undefined,
					meta: {
						id: 'consecutivePollTasks',
						packageName: 'jiraServicedeskCmdbAsyncActions',
						teamName: 'falcons',
					},
				});
			}
		} finally {
			setState({ isPolling: false, pollingReject: undefined, pollingTimeoutIds: undefined });
		}
	};

export const pollTasks = async ({
	consecutiveErrors,
	getState,
	setState,
	dispatch,
	workspaceId,
	createAnalyticsEvent,
	resolve,
	reject,
}: PollTasks): Promise<void> => {
	try {
		const newTasks = await getAllTasks(workspaceId);
		fireTrackAnalytics(createAnalyticsEvent({}), 'pollTasks succeeded');
		// If polling has been cancelled, do not update the state and show flags
		if (getState().isPolling) {
			const newCompletedTasks = getCompletedTasks(getState().tasks, newTasks);
			const mergedTasks = mergeTasks(getState().tasks, newTasks);

			if (newCompletedTasks.length) {
				dispatch(showCompletedFlags(newCompletedTasks));
			}

			setState({
				tasks: mergedTasks,
			});

			// Cancel polling if all tasks are completed or not present
			if (mergedTasks.every((task) => task.status === STATUS_COMPLETED)) {
				resolve();
				return;
			}

			const timeoutId = setTimeout(() => {
				pollTasks({
					consecutiveErrors: 0,
					getState,
					setState,
					dispatch,
					workspaceId,
					createAnalyticsEvent,
					resolve,
					reject,
				});
			}, POLLING_DELAY);
			setState({ pollingTimeoutIds: [...(getState().pollingTimeoutIds ?? []), timeoutId] });
		}
	} catch (error) {
		fireErrorAnalytics({
			error: error instanceof Error ? error : undefined,
			meta: {
				id: 'pollTasks',
				packageName: 'jiraServicedeskCmdbAsyncActions',
				teamName: 'falcons',
			},
		});
		if (getState().isPolling) {
			if (consecutiveErrors + 1 === MAX_ERRORS) {
				reject(new ConsecutivePollingError('Task polling failed due to consecutive errors'));
				return;
			}
			const timeoutId = setTimeout(() => {
				pollTasks({
					consecutiveErrors: consecutiveErrors + 1,
					getState,
					setState,
					dispatch,
					workspaceId,
					createAnalyticsEvent,
					resolve,
					reject,
				});
			}, POLLING_DELAY);
			setState({ pollingTimeoutIds: [...(getState().pollingTimeoutIds ?? []), timeoutId] });
		}
	}
};
