import { NONE } from '../../../constants.tsx';
import type { ObjectUsageLocation, State, ObjectUsageInfo } from '../../../types.tsx';
import { determineObjectUsageInfo } from '../../../usage-info/index.tsx';

export const getObjectUsageInfo = (
	state: State,
	location: ObjectUsageLocation,
): ObjectUsageInfo => {
	const { objectCount, objectLimit, limitState, userRole, billingPeriod, isDismissed } = state;

	if (limitState === 'disabled') {
		return NONE;
	}

	return determineObjectUsageInfo({
		isJiraAdmin: userRole === 'admin',
		objectCount,
		objectLimit,
		billingPeriod,
		location,
		limitState,
		isDismissed,
	});
};
