import { stringify as qsStringify } from 'query-string';
import type { WorkspaceId } from '@atlassian/jira-servicedesk-insight-shared-types/src/common/types/shared-types/index.tsx';
import type { RapidSearchRequest } from '../types/rapid-search.tsx';
import { getCmdbRestBaseUrl } from './index.tsx';

/**
 * /gateway/api/jsm/assets/workspace/${workspaceId}/v1/rapidsearch
 */
const getCmdbRapidSearchUrl = (workspaceId: WorkspaceId) =>
	`${getCmdbRestBaseUrl(workspaceId)}/rapidsearch`;
/**
 * /gateway/api/jsm/assets/workspace/${workspaceId}/v1/rapidsearch/object
 */
export const getCmdbRapidSearchObjectUrl = (
	workspaceId: WorkspaceId,
	queryParams: RapidSearchRequest,
) => `${getCmdbRapidSearchUrl(workspaceId)}/object?${qsStringify(queryParams)}`;
/**
 * /gateway/api/jsm/assets/workspace/${workspaceId}/v1/rapidsearch/objecttype
 */
export const getCmdbRapidSearchObjectTypeUrl = (
	workspaceId: WorkspaceId,
	queryParams: RapidSearchRequest,
) => `${getCmdbRapidSearchUrl(workspaceId)}/objecttype?${qsStringify(queryParams)}`;
