import { fg } from '@atlassian/jira-feature-gating';
import {
	performGetRequest,
	performPostRequest,
} from '@atlassian/jira-fetch/src/utils/requests.tsx';
import {
	getCmdbIconCreateUrl,
	getCmdbIconTokenFetchingUrl,
	getCmdbIconTokenUploadingUrl,
} from '@atlassian/jira-servicedesk-cmdb-rest-urls/src/common/constants/icon.tsx';
import type { WorkspaceId } from '@atlassian/jira-servicedesk-insight-shared-types/src/common/types/shared-types/index.tsx';
import type {
	InsightMediaAuthenticationUpload,
	InsightMediaAuthenticationFetch,
	IconType,
	MediaIconUploadResult,
	MediaBaseUrl,
	CollectionId,
	FileName,
	MediaIssuer,
	MediaJwt,
	MediaFileUuid,
	IconName,
	MediaClientId,
} from '../../common/types.tsx';

type MediaHeaders = {
	// Remove when cleaning up assets_rearch_media_client_auth
	issuer: MediaIssuer;
	clientId: MediaClientId;
	mediaJwtToken: MediaJwt;
};

export const getMediaAuthHeaders = ({
	issuer,
	clientId,
	mediaJwtToken,
}: MediaHeaders): HeadersInit =>
	fg('assets_rearch_media_client_auth')
		? {
				'X-Client-Id': clientId,
				Authorization: `Bearer ${mediaJwtToken}`,
			}
		: {
				'X-Issuer': issuer,
				Authorization: `Bearer ${mediaJwtToken}`,
			};

export const mediaInfoForUpload = (
	workspaceId: WorkspaceId,
): Promise<InsightMediaAuthenticationUpload> =>
	performGetRequest(getCmdbIconTokenUploadingUrl(workspaceId));

type MediaUploadFile = {
	mediaBaseUrl: MediaBaseUrl;
	collectionId: CollectionId;
	fileName: FileName;
	blob: Blob;
} & MediaHeaders;

export const mediaUploadFile = ({
	mediaBaseUrl,
	collectionId,
	fileName,
	// Remove when cleaning up assets_rearch_media_client_auth
	issuer,
	mediaJwtToken,
	blob,
	clientId,
}: MediaUploadFile): Promise<MediaIconUploadResult> =>
	performPostRequest(
		`${String(mediaBaseUrl)}/file/binary?collection=${String(collectionId)}&name=${String(
			fileName,
		)}&deletable=true`,
		{
			headers: {
				...getMediaAuthHeaders({ issuer, clientId, mediaJwtToken }),
				'Content-Type': blob.type,
			},
			body: blob,
		},
	);

export const mediaInfoForPulling = (
	workspaceId: WorkspaceId,
	fileUuid: MediaFileUuid,
): Promise<InsightMediaAuthenticationFetch> =>
	performGetRequest(getCmdbIconTokenFetchingUrl(workspaceId, fileUuid));

type MediaGetFileInfo = {
	mediaBaseUrl: MediaBaseUrl;
	mediaFileUuid: MediaFileUuid;
} & MediaHeaders;

export const mediaGetFileInfo = ({
	mediaBaseUrl,
	mediaFileUuid,
	// Remove when cleaning up assets_rearch_media_client_auth
	issuer,
	mediaJwtToken,
	clientId,
}: MediaGetFileInfo): Promise<MediaIconUploadResult> =>
	performGetRequest(`${String(mediaBaseUrl)}/file/${String(mediaFileUuid)}`, {
		headers: getMediaAuthHeaders({ issuer, clientId, mediaJwtToken }),
	});

export const publishNewIcon = (
	workspaceId: WorkspaceId,
	iconName: IconName,
	mediaFileUuid: MediaFileUuid,
): Promise<IconType> =>
	performPostRequest(getCmdbIconCreateUrl(workspaceId), {
		body: JSON.stringify({
			name: iconName,
			mediaFileUuid,
		}),
	});
