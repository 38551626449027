import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	flagTitle: {
		id: 'servicedesk-cmdb-async-actions.common.flags.completed-flag-bulk-edit.flag-title',
		defaultMessage: `{itemRequestedCount, plural,
    		=0 {Action complete: Edit}
    		one {Action complete: Edit {itemRequestedCount} object}
    		other {Action complete: Edit {itemRequestedCount} objects}
		}`,
		description: 'Flag title when Bulk edit action is completed',
	},
});
