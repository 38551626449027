import { getCmdbTaskByIdUrl } from '@atlassian/jira-servicedesk-cmdb-rest-urls/src/common/constants/task.tsx';
import type { CompletedTask } from '@atlassian/jira-servicedesk-insight-shared-types/src/common/types/task.tsx';
import { matchTaskByOperationType } from '@atlassian/jira-servicedesk-insight-shared-types/src/common/utils/task-type-matchers/index.tsx';
import { bulkDeleteFlagHandler } from '../../../common/ui/flags/completed-flag-bulk-delete/index.tsx';
import { bulkEditFlagHandler } from '../../../common/ui/flags/completed-flag-bulk-edit/index.tsx';
import { bulkExportFlagHandler } from '../../../common/ui/flags/completed-flag-bulk-export/index.tsx';
import { qrCodeFlagHandler } from '../../../common/ui/flags/completed-flag-qr-code/index.tsx';
import type { CmdbAsyncActionStoreAction } from '../../types.tsx';
import { dismissTask } from '../dismiss-task/index.tsx';

export const showCompletedFlags =
	(completedTasks: CompletedTask[]): CmdbAsyncActionStoreAction<void> =>
	({ dispatch }, { workspaceId, showFlag, dismissFlag }) => {
		completedTasks.forEach((task) => {
			// Dismiss "Action started" flag if still present
			dismissFlag(task.identifier);
			// Pass the dismiss action to the flag component
			const onDismissed = () => {
				// Dismiss the flag from the UI
				dismissFlag(task.identifier);
				// Dismiss the task on the server
				dispatch(dismissTask({ taskId: task.identifier }));
			};
			const taskUrl = getCmdbTaskByIdUrl(workspaceId, task.identifier);
			showFlag(
				matchTaskByOperationType(task, {
					BulkEdit: (bulkEditTask) =>
						bulkEditFlagHandler({ task: bulkEditTask, taskUrl, onDismissed }),
					BulkDelete: (bulkDeleteTask) =>
						bulkDeleteFlagHandler({ task: bulkDeleteTask, taskUrl, onDismissed }),
					BulkExport: (bulkExportTask) =>
						bulkExportFlagHandler({ task: bulkExportTask, taskUrl, onDismissed }),
					QrCode: (qrCodeTask) => qrCodeFlagHandler({ task: qrCodeTask, taskUrl, onDismissed }),
				}),
			);
		});
	};
