import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { performGetRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import { fireTrackAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { getDataManagerFeatureFlagStatusByName } from '@atlassian/jira-servicedesk-cmdb-rest-urls/src/common/constants/data-manager.tsx';
import { getCmdbGlobalConfigUrl } from '@atlassian/jira-servicedesk-cmdb-rest-urls/src/common/constants/global.tsx';
import { DATA_MANAGER_GA_FF } from '../../common/constants.tsx';
import type { ActionApi, GlobalConfig } from '../../common/types.tsx';

export const fetchGlobalConfig =
	(shouldRefetch = true): ActionApi =>
	async ({ setState, getState }, { workspaceId, createAnalyticsEvent }) => {
		if (getState().loading || (!shouldRefetch && getState().config)) {
			return;
		}

		setState({ loading: true, error: undefined });

		try {
			const response: GlobalConfig = await performGetRequest(getCmdbGlobalConfigUrl(workspaceId));

			let dataManagerGAFeatureFlagResponse = false;
			try {
				dataManagerGAFeatureFlagResponse = await performGetRequest(
					getDataManagerFeatureFlagStatusByName(workspaceId, DATA_MANAGER_GA_FF),
				);
			} catch (error) {
				fireErrorAnalytics({
					error: error instanceof Error ? error : undefined,
					meta: {
						id: 'fetchDataManagerGAFeatureFlag',
						packageName: 'jiraServicedeskInsightGlobalConfigurationStore',
						teamName: 'falcons',
					},
				});
			}

			const iconListWithServiceOTEntriesRemoved = response
				? response.icons.filter(
						(item) =>
							item.name !== 'Applications' &&
							item.name !== 'Business Services' &&
							item.name !== 'Capabilities' &&
							item.name !== 'Software Services',
					)
				: undefined;

			setState({
				loading: false,
				config: {
					...response,
					restrictedObjectSchemaIds: response?.restrictedObjectSchemaIds ?? [],
					icons: iconListWithServiceOTEntriesRemoved || [],
				},
				referenceTypes: {
					...getState().referenceTypes,
					data: response ? response.referenceTypes : undefined,
				},
				statusTypes: {
					...getState().statusTypes,
					data: response ? response.statusTypes : undefined,
				},
				icons: {
					...getState().icons,
					data: iconListWithServiceOTEntriesRemoved,
				},
				dataManager: {
					...getState().dataManager,
					featureFlags: {
						...getState().dataManager.featureFlags,
						[DATA_MANAGER_GA_FF]: dataManagerGAFeatureFlagResponse,
					},
				},
			});

			fireTrackAnalytics(createAnalyticsEvent({}), 'fetchInsightGlobalConfig succeeded');
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			setState({ loading: false, error });

			fireErrorAnalytics({
				meta: {
					id: 'fetchInsightGlobalConfig',
					packageName: 'jiraServicedeskInsightGlobalConfigurationStore',
					teamName: 'falcons',
				},
				error,
			});

			throw error;
		}
	};
