import React, { useRef, useLayoutEffect } from 'react';
import { IconButton, type IconButtonProps } from '@atlaskit/button/new';
import Tooltip from '@atlaskit/tooltip';

type Props = IconButtonProps & {
	/**
	 * Optional separate label for the tooltip. If unset, the tooltip has the label of the button.
	 */
	tooltipLabel?: string;
};

/**
 * __Action__.
 *
 * An action is a generic icon button displayed top right of the code block.
 */
export const Action = ({ icon, label, tooltipLabel, isLoading, ...buttonProps }: Props) => {
	const updateTooltip = useRef<() => void>();

	useLayoutEffect(() => {
		updateTooltip.current?.();
	}, [tooltipLabel, label]);

	return (
		<Tooltip
			content={({ update }) => {
				updateTooltip.current = update;
				return tooltipLabel || label;
			}}
			position="top"
		>
			<IconButton
				isLoading={isLoading}
				appearance="subtle"
				icon={icon}
				label={label}
				spacing="compact"
				{...buttonProps}
			/>
		</Tooltip>
	);
};
