import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';

export const fetchEntitlementDetails = async (entitlementId: string) =>
	fetchJson(`/gateway/api/ccp/api/v1/entitlements/${entitlementId}/details`);

export const fetchHamsPremiumEstimate = async (cloudId: string, entitlementId: string) =>
	fetchJson(
		`/gateway/api/billing-ux/cloud/${cloudId}/billing/entitlements/${entitlementId}/edition/premium/estimate`,
	);

export const fetchHamsEntitlementDetails = async (cloudId: string) =>
	fetchJson(`/gateway/api/billing-ux/cloud/${cloudId}/billing/entitlement-group`);
