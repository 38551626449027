import { fetchRecentObjects } from './fetch-recent-objects/index.tsx';
import { fetchSchemas } from './fetch-schemas/index.tsx';
import { insertCreatedSchema } from './insert-created-schema/index.tsx';

export const actions = {
	fetchSchemas,
	fetchRecentObjects,
	insertCreatedSchema,
} as const;

export type Actions = typeof actions;
