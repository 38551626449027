import { createContainer, createStore, createHook } from '@atlassian/react-sweet-state';
import type { ContainerProps, State, ActionApi } from '../../common/types.tsx';
import { actions, type Actions } from './actions/index.tsx';

const initialState: State = {
	schemaState: { type: 'loading' },
	recentObjectState: { type: 'loading' },
};

const Store = createStore({
	initialState,
	actions,
});

export const useLandingPageState = createHook(Store, {
	selector: (state) => state,
});

export const autoFetchLandingPageData =
	(): ActionApi =>
	async ({ dispatch }) => {
		await Promise.all([dispatch(actions.fetchRecentObjects()), dispatch(actions.fetchSchemas())]);
	};

export const InsightLandingPageContainer = createContainer<State, Actions, ContainerProps>(Store, {
	onInit: autoFetchLandingPageData,
});
