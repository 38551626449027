import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { fireTrackAnalytics } from '@atlassian/jira-product-analytics-bridge';
import {
	OPERATION_TYPE_BULK_QR_CODE,
	STATUS_QUEUED,
} from '@atlassian/jira-servicedesk-insight-shared-types/src/common/types/task.tsx';
import { mergeTasks } from '../../../common/utils/merge-tasks/index.tsx';
import { createAsyncBulkQrCodeTask } from '../../services/create-async-bulk-qr-code-task/index.tsx';
import type { AsyncBulkQrCodeParams } from '../../services/create-async-bulk-qr-code-task/types.tsx';
import type { CmdbAsyncActionStoreAction } from '../../types.tsx';
import { showErrorFlag } from '../show-error-flag/index.tsx';
import { showSuccessFlag } from '../show-success-flag/index.tsx';
import { startTaskPolling } from '../start-task-polling/index.tsx';

export type SubmitBulkQrCodeTaskProps = {
	size: number;
	objectCount: string;
} & AsyncBulkQrCodeParams;

export const submitBulkQrCodeTask =
	({
		objectCount,
		size,
		...body
	}: SubmitBulkQrCodeTaskProps): CmdbAsyncActionStoreAction<Promise<void>> =>
	async ({ setState, getState, dispatch }, { workspaceId, createAnalyticsEvent }) => {
		if (getState().bulkQrCode.isSubmitting) {
			return;
		}
		setState({ bulkQrCode: { isSubmitting: true } });

		try {
			const { taskId } = await createAsyncBulkQrCodeTask(workspaceId, size, { ...body });

			setState({
				tasks: mergeTasks(getState().tasks, [
					{
						identifier: taskId,
						operationType: OPERATION_TYPE_BULK_QR_CODE,
						status: STATUS_QUEUED,
					},
				]),
				bulkQrCode: { isSubmitting: false },
			});

			fireTrackAnalytics(createAnalyticsEvent({}), 'submitAsyncBulkQrCodeTask succeeded');
			dispatch(
				showSuccessFlag({
					id: taskId,
					objectCount,
					action: OPERATION_TYPE_BULK_QR_CODE,
				}),
			);
			dispatch(startTaskPolling());
		} catch (error) {
			fireErrorAnalytics({
				error: error instanceof Error ? error : undefined,
				meta: {
					id: 'submitAsyncBulkQrCodeTask',
					packageName: 'jiraServicedeskCmdbAsyncActions',
					teamName: 'falcons',
				},
			});

			setState({ bulkQrCode: { isSubmitting: false } });

			dispatch(showErrorFlag({ action: OPERATION_TYPE_BULK_QR_CODE, objectCount }));

			throw error;
		}
	};
