import { createElement, type ReactElement } from 'react';
import Icon, { type IconProps } from '@atlaskit/icon';

/**
 * There's a bug in the version of eslint that JFE is currently using that
 * is preventing us from spreading props onto @atlaskit/icon directly when
 * written as JSX.
 * As a workaround for now, we are using the non-jsx React.createElement
 * syntax to achieve the same thing.
 * See https://atlassian.slack.com/archives/CFHSME8EB/p1612997374280800
 * for more context
 */
export const createCustomAKIcon =
	(glyph: (props: { [key: string]: string }) => ReactElement) => (props: IconProps) =>
		// @ts-expect-error - TS2769 - No overload matches this call.
		createElement(Icon, { glyph, ...props });
