import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	completedFlagDescriptionNonfinal: {
		id: 'servicedesk-cmdb-async-actions.common.completed-flag-description-nonfinal',
		defaultMessage: 'This action was completed successfully',
		description: 'Flag description after completing bulk async task successfully',
	},
	completedFlagErrorDescriptionNonfinal: {
		id: 'servicedesk-cmdb-async-actions.common.completed-flag-error-description-nonfinal',
		defaultMessage:
			'Use the link below to check the outcome of your bulk action and view the objects that encountered error',
		description: 'Flag description after completing bulk async task successfully',
	},
	completedFlagViewReportNonfinal: {
		id: 'servicedesk-cmdb-async-actions.common.flags.completed-flag-view-report-nonfinal',
		defaultMessage: 'View report',
		description: 'Flag action to view report',
	},
});
