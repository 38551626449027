import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { performGetRequest, performPutRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import { fireTrackAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { getDataManagerProvisioningStatusUrl } from '@atlassian/jira-servicedesk-cmdb-rest-urls/src/common/constants/data-manager.tsx';
import type { ActionApi, ProvisioningStatusResponse } from '../../common/types.tsx';

type UpdateProvisioningStatusParams = {
	isEnabled: boolean;
};

export const updateProvisioningStatus =
	({ isEnabled }: UpdateProvisioningStatusParams): ActionApi =>
	async ({ setState, getState }, { workspaceId, createAnalyticsEvent }) => {
		const state = getState();

		if (state.dataManager.provisioningUpdateLoading) {
			return;
		}

		setState({
			...state,
			dataManager: {
				...state.dataManager,
				isProvisioned: isEnabled,
				provisioningUpdateLoading: true,
			},
		});

		try {
			const requestBody = {
				enabled: isEnabled,
			};

			await performPutRequest(getDataManagerProvisioningStatusUrl(workspaceId), {
				body: JSON.stringify(requestBody),
			});

			const result: ProvisioningStatusResponse = await performGetRequest(
				getDataManagerProvisioningStatusUrl(workspaceId),
			);

			setState({
				...state,
				dataManager: {
					...state.dataManager,
					isProvisioned: result.enabled === 'ON',
					provisioningUpdateLoading: false,
				},
			});
			fireTrackAnalytics(createAnalyticsEvent({}), 'updateProvisioningStatus succeeded');

			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			setState({
				...state,
				dataManager: {
					...state.dataManager,
					provisioningUpdateLoading: false,
					provisioningUpdateError: error,
				},
			});

			fireErrorAnalytics({
				meta: {
					id: 'updateProvisioningStatus',
					packageName: 'jiraServicedeskInsightGlobalConfigurationStore',
				},
				error,
			});

			throw error;
		}
	};
