import { performPostRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import { getCmdbAsyncObjectExportUrl } from '@atlassian/jira-servicedesk-cmdb-rest-urls/src/common/constants/object.tsx';
import type { WorkspaceId } from '@atlassian/jira-servicedesk-insight-shared-types/src/common/types/shared-types/index.tsx';
import type { BulkActionResponse } from '@atlassian/jira-servicedesk-insight-shared-types/src/common/types/task.tsx';
import { generateFilterBody } from '../common/utils/generate-filter-body/index.tsx';
import type { AsyncBulkExportParams } from './types.tsx';

export const createAsyncBulkExportTask = (
	workspaceId: WorkspaceId,
	params: AsyncBulkExportParams,
): Promise<BulkActionResponse> => {
	if (params.filter.qlQueryParams == null && params.filter.filterParams == null) {
		throw new Error(
			'Must supply either `filters` or `qlQueryParams` to `createAsyncBulkExportTask`',
		);
	}

	const body = generateFilterBody(params.filter);

	return performPostRequest(getCmdbAsyncObjectExportUrl(workspaceId), {
		body: JSON.stringify(body),
	});
};
