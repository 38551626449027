import { useEffect, useState } from 'react';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { SERVICE_DESK } from '@atlassian/jira-shared-types/src/application-key.tsx';
import { STANDARD_EDITION } from '@atlassian/jira-shared-types/src/edition.tsx';
import { useAppEditions } from '@atlassian/jira-tenant-context-controller/src/components/app-editions/index.tsx';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import { PACKAGE_NAME, TEAM_NAME } from '../../common/constants.tsx';
import { fetchEntitlementDetails } from '../../common/utils/index.tsx';
import { GRACE_PERIOD_DAYS, getTrialDaysLeft } from './utils.tsx';

export const useGetUserEligibility = () => {
	const [isUserEligible, setIsUserEligible] = useState<boolean>(false);
	const tenantContext = useTenantContext();
	const { serviceDesk: jsmEdition } = useAppEditions();

	useEffect(() => {
		const loadUserEligibilityInfo = async () => {
			try {
				if (jsmEdition !== STANDARD_EDITION) {
					setIsUserEligible(false);
					return;
				}

				const entitlementDetails = tenantContext.productEntitlementDetails?.[SERVICE_DESK];
				const { billingSourceSystem, entitlementId, trialEndTime } = entitlementDetails || {};

				const isUsingCcp = billingSourceSystem === 'CCP';
				if (isUsingCcp) {
					if (!entitlementId) {
						throw new Error('Empty entitlement ID');
					}
					const ccpEntitlementDetails = await fetchEntitlementDetails(entitlementId);
					const isCCPPaidPlan = ccpEntitlementDetails?.subscriptionv2?.trial === null;

					setIsUserEligible(isCCPPaidPlan);
				} else if (trialEndTime) {
					// HAMS Billing System
					const trialDaysLeft = getTrialDaysLeft(Date.now(), trialEndTime);
					const notInTrialOrGracePeriod = trialDaysLeft < -GRACE_PERIOD_DAYS;
					setIsUserEligible(notInTrialOrGracePeriod);
				} else {
					setIsUserEligible(true);
				}
			} catch (e) {
				fireErrorAnalytics({
					meta: {
						id: 'useGetUserEligibility',
						packageName: PACKAGE_NAME,
						teamName: TEAM_NAME,
					},
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					error: e as Error,
					sendToPrivacyUnsafeSplunk: true,
				});
			}
		};

		loadUserEligibilityInfo();
	}, [jsmEdition, tenantContext.productEntitlementDetails]);

	return {
		isUserEligible,
	};
};
