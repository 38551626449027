import { dismissTask } from './dismiss-task/index.tsx';
import { showCompletedFlags } from './show-completed-flags/index.tsx';
import { showErrorFlag } from './show-error-flag/index.tsx';
import { showSuccessFlag } from './show-success-flag/index.tsx';
import { startTaskPolling } from './start-task-polling/index.tsx';
import { submitBulkDeleteTask } from './submit-async-delete-task/index.tsx';
import { submitBulkEditTask } from './submit-async-edit-task/index.tsx';
import { submitBulkExportTask } from './submit-async-export-task/index.tsx';
import { submitBulkQrCodeTask } from './submit-async-qr-code-task/index.tsx';

export const actions = {
	startTaskPolling,
	submitBulkExportTask,
	submitBulkQrCodeTask,
	submitBulkDeleteTask,
	submitBulkEditTask,
	dismissTask,
	showCompletedFlags,
	showErrorFlag,
	showSuccessFlag,
} as const;

export type Actions = typeof actions;
