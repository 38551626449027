import React, { type ComponentType } from 'react';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { lazy } from '@atlassian/react-loosely-lazy';
import type { ReportsLinkProps } from './link/index.tsx';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const ReportsLink: ComponentType<ReportsLinkProps> = lazy(
	() => import(/* webpackChunkName: "jsm-insight-landing-page-reports-link" */ './link/index'),
);

export const AsyncInsightLandingPageReportsLink = (props: ReportsLinkProps) => (
	<Placeholder name="jsm-insight-landing-page-reports-link" fallback={null}>
		<ReportsLink {...props} />
	</Placeholder>
);
