import React, { useState, type ReactNode } from 'react';
import { CustomThemeButton as Button, Theme } from '@atlaskit/button';
import Image from '@atlaskit/image';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import Modal, { ModalTransition, ModalTitle } from '@atlaskit/modal-dialog';
import { modalButtonTheme } from '@atlaskit/onboarding';
import { Box, xcss, Flex } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import ShortcutScope from '@atlassian/jira-common-components-keyboard-shortcuts/src/shortcut-scope.tsx';
import CoordinationClient from '@atlassian/jira-engagement/src/ui/coordination-client/index.tsx';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/index.tsx';
import { FormattedMessage, useIntl } from '@atlassian/jira-intl';
import { JSM_CMDB_DATA_MANAGER_CHANGE_BOARDING } from '../common/constants.tsx';
import modalHeroBg from './assets/background.png';
import { messages } from './messages.tsx';

type Props = {
	/** This prop is provided by CoordinationClient using `cloneElement` which
	 * is why it appears this component never actually gets passed this prop. */
	// eslint-disable-next-line jira/react/handler-naming
	stop?: () => void;
};

export const CmdbDataManagerChangeBoardingModal = (props: Props) => {
	const { formatMessage } = useIntl();
	const [isFinished, setIsFinished] = useState(false);

	const finishChangeBoarding = () => {
		props.stop?.();
		setIsFinished(true);
	};

	const onDone = () => {
		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		window.open(
			'https://www.atlassian.com/software/jira/service-management/features/assets-data-manager',
			'_blank',
		);
		finishChangeBoarding();
	};

	const onClose = () => {
		finishChangeBoarding();
	};

	if (isFinished) {
		return null;
	}

	return (
		<ShortcutScope>
			<Theme.Provider value={modalButtonTheme}>
				<Modal
					width={600}
					onClose={onClose}
					autoFocus={false}
					testId="servicedesk-cmdb-data-manager-change-boarding.ui.modal"
				>
					<Box xcss={modalContainer}>
						<Box xcss={modaImageContainer}>
							<Image
								src={modalHeroBg}
								srcDark={modalHeroBg}
								alt={formatMessage(messages.modalHeroBannerImageAltText)}
								testId="servicedesk-cmdb-data-manager-change-boarding.ui.image"
							/>
						</Box>
						<Box xcss={noBorderContainer}>
							<Box
								xcss={modalTitleWrapper}
								paddingBlockStart="space.500"
								paddingBlockEnd="space.200"
								paddingInlineStart="space.600"
								paddingInlineEnd="space.600"
							>
								<ModalTitle>{formatMessage(messages.modalTitle)}</ModalTitle>
							</Box>
							<Box
								xcss={modalContentWrapper}
								paddingBlockStart="space.0"
								paddingBlockEnd="space.0"
								paddingInlineStart="space.600"
								paddingInlineEnd="space.600"
							>
								<FormattedMessage
									{...messages.modalContent}
									values={{
										p: (msg: ReactNode[]) => <p>{msg}</p>,
									}}
								/>
							</Box>
						</Box>
						<Box
							paddingBlockStart="space.400"
							paddingBlockEnd="space.500"
							paddingInlineStart="space.500"
							paddingInlineEnd="space.500"
							xcss={noBorderContainer}
						>
							<Flex direction="row" justifyContent="center" alignItems="center" gap="space.150">
								<Button
									appearance="subtle"
									onClick={onClose}
									testId="servicedesk-cmdb-data-manager-change-boarding.ui.cancel-button"
								>
									{formatMessage(messages.cancelButton)}
								</Button>
								<Button
									appearance="primary"
									onClick={onDone}
									testId="servicedesk-cmdb-data-manager-change-boarding.ui.confirm-button"
								>
									{formatMessage(messages.confirmButton)}
								</Button>
							</Flex>
						</Box>
					</Box>
				</Modal>
			</Theme.Provider>
		</ShortcutScope>
	);
};

export const CmdbDataManagerChangeBoarding = () => (
	<JSErrorBoundary
		id="CmdbDataManagerChangeBoarding"
		packageName="jiraServicedeskCmdbDataManagerChangeBoarding"
		fallback="flag"
	>
		<ModalTransition>
			<CoordinationClient messageId={JSM_CMDB_DATA_MANAGER_CHANGE_BOARDING}>
				<CmdbDataManagerChangeBoardingModal />
			</CoordinationClient>
		</ModalTransition>
	</JSErrorBoundary>
);

const modalTitleWrapper = xcss({
	textAlign: 'center',
	fontWeight: `${token('font.weight.semibold', '510')}`,
});

const modalContentWrapper = xcss({
	textAlign: 'center',
});

const modalContainer = xcss({
	backgroundColor: 'elevation.surface',
	borderRadius: `${token('border.radius', '3px')}`,
});

const modaImageContainer = xcss({
	borderRadius: `${token('border.radius', '3px')} ${token('border.radius', '3px')} 0 0`,
	overflow: 'hidden',
});

const noBorderContainer = xcss({
	border: 'none',
});
