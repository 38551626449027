/* eslint-disable max-classes-per-file */
export class CancelledPollingError extends Error {
	constructor(message: string) {
		super(message);
		this.name = 'CancelledPollingError';
	}
}

export class ConsecutivePollingError extends Error {
	constructor(message: string) {
		super(message);
		this.name = 'ConsecutivePollingError';
	}
}
