import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	noResultsFoundMessage: {
		id: 'servicedesk-insight-rapid-search-bar.popup-content.no-results-found-message',
		defaultMessage: "We couldn't find any results matching your search",
		description: 'Message informing the user that there were no object results',
	},
	objects: {
		id: 'servicedesk-insight-rapid-search-bar.popup-content.objects',
		defaultMessage: 'Objects',
		description: 'Label for a list of objects',
	},
	objectTypes: {
		id: 'servicedesk-insight-rapid-search-bar.popup-content.object-types',
		defaultMessage: 'Object types',
		description: 'Label for a list of object types',
	},
	noneFound: {
		id: 'servicedesk-insight-rapid-search-bar.popup-content.none-found',
		defaultMessage: 'None found',
		description: "Message shown when no objects or object types are found for the user's search",
	},
	typeMoreToSeeResults: {
		id: 'servicedesk-insight-rapid-search-bar.popup-content.type-more-to-see-results',
		defaultMessage: 'Keep typing to see search results',
		description: 'Informs the user to type more keys on their keyboard to get search results',
	},
});
