import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { performPutRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import { fireTrackAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { getCmdbIconByIdUrl } from '@atlassian/jira-servicedesk-cmdb-rest-urls/src/common/constants/icon.tsx';
import type { IconType, ActionApi } from '../../common/types.tsx';

export type IconProperties = {
	name?: string;
};

export type RequestBodyType = {
	iconId: string;
	updatedProperties: IconProperties;
};

export const setIcon =
	(updatedIcon: IconType): ActionApi =>
	async ({ setState, getState }) => {
		const state = getState();
		const updatedData = state.icons.data?.map(
			(
				icon:
					| IconType
					| {
							id: string;
							name: string;
							url16: string;
							url48: string;
					  },
			) => (icon.id === updatedIcon.id ? updatedIcon : icon),
		);

		setState({
			...state,
			icons: { ...state.icons, data: updatedData },
		});
	};

export const updateIcon =
	({ iconId, updatedProperties }: RequestBodyType): ActionApi =>
	async ({ setState, getState }, { workspaceId, createAnalyticsEvent }) => {
		const state = getState();

		setState({
			...state,
			icons: { ...state.icons, updateLoading: true },
		});

		try {
			const response = await performPutRequest(getCmdbIconByIdUrl(workspaceId, iconId), {
				body: JSON.stringify(updatedProperties),
			});
			const updatedState = getState();
			const updatedData = updatedState.icons.data?.map(
				(
					icon:
						| IconType
						| {
								id: string;
								name: string;
								url16: string;
								url48: string;
						  },
				) => (icon.id === response.id ? response : icon),
			);

			setState({
				...updatedState,
				icons: {
					...updatedState.icons,
					updateLoading: false,
					data: updatedData,
				},
			});

			fireTrackAnalytics(createAnalyticsEvent({}), 'updateIcon succeeded');
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			const updatedState = getState();

			setState({
				...updatedState,
				icons: {
					...updatedState.icons,
					updateLoading: false,
					updateError: error,
				},
			});

			fireErrorAnalytics({
				meta: {
					id: 'updateIcon',
					packageName: 'jiraServicedeskInsightGlobalConfigurationStore',
					teamName: 'falcons',
				},
				error,
			});

			throw error;
		}
	};
