import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	placeholderNoneEntered: {
		id: 'servicedesk-insight-attribute-views.common.placeholder-none-entered',
		defaultMessage: 'None entered',
		description: 'Place holder text for an CMDB attribute when it does not have a value set.',
	},
	placeholderNoneSelected: {
		id: 'servicedesk-insight-attribute-views.common.placeholder-none-selected',
		defaultMessage: 'None selected',
		description: 'Place holder text for an CMDB attribute when it does not have a value selected.',
	},
});
