import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	objectSchemas: {
		id: 'servicedesk-insight-object-container.object-schemas',
		defaultMessage: 'Recent objects',
		description: 'Title of the object list',
	},
	viewMore: {
		id: 'servicedesk-insight-object-container.view-more',
		defaultMessage: 'View more',
		description: 'Link to allow the user to view more objects in the list',
	},
	noObjectsToShow: {
		id: 'servicedesk-insight-object-container.no-objects-to-show',
		defaultMessage: 'No recent objects to show',
		description: 'Message display when no recent object can be displayed',
	},
});
