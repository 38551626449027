import { performGetRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import {
	getCmdbRapidSearchObjectTypeUrl,
	getCmdbRapidSearchObjectUrl,
} from '@atlassian/jira-servicedesk-cmdb-rest-urls/src/common/constants/rapid-search.tsx';
import type {
	RapidSearchObjectsResponse,
	RapidSearchObjectTypesResponse,
} from '@atlassian/jira-servicedesk-insight-shared-types/src/common/types/rapid-search-result.tsx';
import type { WorkspaceId } from '@atlassian/jira-servicedesk-insight-shared-types/src/common/types/shared-types/index.tsx';

export const fetchRapidSearchObjects = async ({
	workspaceId,
	query,
}: {
	workspaceId: WorkspaceId;
	query: string;
}): Promise<RapidSearchObjectsResponse> =>
	performGetRequest(getCmdbRapidSearchObjectUrl(workspaceId, { searchTerm: query }));

export const fetchRapidSearchObjectTypes = async ({
	workspaceId,
	query,
}: {
	workspaceId: WorkspaceId;
	query: string;
}): Promise<RapidSearchObjectTypesResponse> =>
	performGetRequest(getCmdbRapidSearchObjectTypeUrl(workspaceId, { searchTerm: query }));
