import { dismissMessage } from './dismiss-message/index.tsx';
import { refreshObjectCount } from './refresh-object-count/index.tsx';
import { setInitialData } from './set-initial-data/index.tsx';

export const actions = {
	setInitialData,
	dismissMessage,
	refreshObjectCount,
} as const;

export type Actions = typeof actions;
