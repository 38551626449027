import type { CreateUIAnalyticsEvent } from '@atlaskit/analytics-next';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { performGetRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import { fireTrackAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { getCmdbGlobalConfigUrl } from '@atlassian/jira-servicedesk-cmdb-rest-urls/src/common/constants/global.tsx';
import type { GlobalConfig } from '@atlassian/jira-servicedesk-insight-global-configuration-store/src/common/types.tsx';
import { LOCAL_STORAGE, DISMISSED_FOR_THRESHOLD_TYPE_KEY } from '../../../constants.tsx';
import type { ActionApi } from '../../../types.tsx';
import { determineThreshold } from '../../../usage-info/index.tsx';

export const refreshObjectCount =
	({ createAnalyticsEvent }: { createAnalyticsEvent?: CreateUIAnalyticsEvent }): ActionApi =>
	async ({ setState, getState }) => {
		try {
			const currentState = getState();
			const { workspaceId } = currentState;
			const response: GlobalConfig = await performGetRequest(getCmdbGlobalConfigUrl(workspaceId));
			const { limitInfo } = response;

			const { limitState = 'disabled', objectCount, objectLimit } = limitInfo;
			const updatedState = {
				...currentState,
				limitState,
				objectCount,
				objectLimit,
			};

			if (isStoredIsDismissedValueStale({ objectCount, objectLimit })) {
				LOCAL_STORAGE.remove(DISMISSED_FOR_THRESHOLD_TYPE_KEY);
				setState({
					...updatedState,
					isDismissed: false,
				});
			} else {
				setState(updatedState);
			}

			if (createAnalyticsEvent) {
				fireTrackAnalytics(createAnalyticsEvent({}), 'refreshObjectCount succeeded');
			}
		} catch (untypedError) {
			const error: Error | undefined = untypedError instanceof Error ? untypedError : undefined;
			fireErrorAnalytics({
				meta: {
					id: 'refreshObjectCount',
					packageName: 'jiraServicedeskCmdbObjectUsageMessaging',
					teamName: 'krispy-krew',
				},
				error,
			});
		}
	};

const isStoredIsDismissedValueStale = ({
	objectCount,
	objectLimit,
}: {
	objectCount: number;
	objectLimit: number;
}) => {
	const threshold = determineThreshold({ objectCount, objectLimit });
	const dismissState = LOCAL_STORAGE.get(DISMISSED_FOR_THRESHOLD_TYPE_KEY);
	return dismissState && dismissState !== threshold && threshold !== 'none';
};
