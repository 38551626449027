import React from 'react';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { lazy } from '@atlassian/react-loosely-lazy';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const LazyCmdbCompassChangeboarding = lazy(() =>
	import(/* webpackChunkName: "cmdb-compass-changeboarding" */ './ui').then(
		(module) => module.CmdbCompassChangeboarding,
	),
);

export const AsyncCmdbCompassChangeboarding = () => (
	<Placeholder name="cmdb-compass-changeboarding" fallback={null}>
		<LazyCmdbCompassChangeboarding />
	</Placeholder>
);
