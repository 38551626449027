import { addIcon } from './add-icon/index.tsx';
import { createReferenceType } from './create-reference-type/index.tsx';
import { createStatusType } from './create-status-type/index.tsx';
import { deleteIcon } from './delete-icon/index.tsx';
import { deleteReferenceType } from './delete-reference-type/index.tsx';
import { deleteStatusType } from './delete-status-type/index.tsx';
import { fetchGlobalConfig } from './fetch-global-config/index.tsx';
import { fetchMyDataManagerRoles } from './fetch-my-data-manager-roles/index.tsx';
import { fetchPrincipalsForRoles } from './fetch-principals-for-roles/index.tsx';
import { fetchProvisioningStatus } from './fetch-provisioning-status/index.tsx';
import { fetchDataManagerTokens } from './fetch-tokens/index.tsx';
import { updateDataManagerRole } from './update-data-manager-role/index.tsx';
import { setIcon, updateIcon } from './update-icon/index.tsx';
import { updateProvisioningStatus } from './update-provisioning-status/index.tsx';
import { setReferenceType, updateReferenceType } from './update-reference-type/index.tsx';
import { setStatusType, updateStatusType } from './update-status-type/index.tsx';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { IconProperties } from './update-icon';
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { ReferenceTypeProperties } from './update-reference-type';
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { StatusTypeProperties } from './update-status-type';

export const actions = {
	createReferenceType,
	createStatusType,
	deleteIcon,
	addIcon,
	deleteReferenceType,
	deleteStatusType,
	fetchGlobalConfig,
	setIcon,
	updateIcon,
	setReferenceType,
	updateReferenceType,
	fetchPrincipalsForRoles,
	fetchMyDataManagerRoles,
	updateDataManagerRole,
	fetchProvisioningStatus,
	updateProvisioningStatus,
	fetchDataManagerTokens,
	setStatusType,
	updateStatusType,
} as const;

export type Actions = typeof actions;
