import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	admin80PercentMonthly: {
		id: 'servicedesk-cmdb-object-usage-messaging.limits-banner.admin-80-percent-monthly',
		defaultMessage:
			"<b>You're running out of available Assets objects (< 20%).</b> Delete objects or <a>increase usage limit</a>.",
		description:
			'Banner message to display at top of page when admin, on monthly payment cycle, is at 80% usage',
	},
	admin90PercentMonthly: {
		id: 'servicedesk-cmdb-object-usage-messaging.limits-banner.admin-90-percent-monthly',
		defaultMessage:
			"<b>You're nearly out of available Assets objects (< 10%).</b> Delete objects or <a>increase usage limit</a>.",
		description:
			'Banner message to display at top of page when admin, on monthly payment cycle, is at 90% usage',
	},
	adminLimitReachedMonthly: {
		id: 'servicedesk-cmdb-object-usage-messaging.limits-banner.admin-limit-reached-monthly',
		defaultMessage:
			"<b>You're out of available Assets objects.</b> Delete objects or <a>increase usage limit</a> to add more objects.",
		description:
			'Banner message to display at top of page when admin, on monthly payment cycle, is at 100% usage',
	},
	admin80PercentYearly: {
		id: 'servicedesk-cmdb-object-usage-messaging.limits-banner.admin-80-percent-yearly',
		defaultMessage:
			"<b>You're running out of available Assets objects (< 20%).</b> Delete objects or <a>get extra objects</a>.",
		description:
			'Banner message to display at top of page when admin, on yearly payment cycle, is at 80% usage',
	},
	admin90PercentYearly: {
		id: 'servicedesk-cmdb-object-usage-messaging.limits-banner.admin-90-percent-yearly',
		defaultMessage:
			'<b>You’re nearly out of available Assets objects (< 10%).</b> Delete objects or <a>get extra objects</a>.',
		description:
			'Banner message to display at top of page when admin, on yearly payment cycle, is at 90% usage',
	},
	adminLimitReachedYearly: {
		id: 'servicedesk-cmdb-object-usage-messaging.limits-banner.admin-limit-reached-yearly',
		defaultMessage:
			'<b>You’re out of available Assets objects.</b> Delete objects or <a>get extra objects</a> to add more objects.',
		description:
			'Banner message to display at top of page when admin, on yearly payment cycle, is at 100% usage',
	},
	agent90PercentMonthly: {
		id: 'servicedesk-cmdb-object-usage-messaging.limits-banner.agent-90-percent-monthly',
		defaultMessage:
			'<b>You’re nearly out of available Assets objects (< 10%).</b> Contact your admin to increase the usage limit.',
		description:
			'Banner message to display at top of page when agent, on monthly payment cycle, is at 90% usage',
	},
	agentLimitReachedMonthly: {
		id: 'servicedesk-cmdb-object-usage-messaging.limits-banner.agent-limit-reached-monthly',
		defaultMessage:
			'<b>You’re out of available Assets objects.</b> Contact your admin to increase the usage limit.',
		description:
			'Banner message to display at top of page when agent, on monthly payment cycle, is at 100% usage',
	},
	agent90PercentYearly: {
		id: 'servicedesk-cmdb-object-usage-messaging.limits-banner.agent-90-percent-yearly',
		defaultMessage:
			'<b>You’re nearly out of available Assets objects (< 10%).</b> Contact your admin to increase the usage limit.',
		description:
			'Banner message to display at top of page when agent, on yearly payment cycle, is at 90% usage',
	},
	agentLimitReachedYearly: {
		id: 'servicedesk-cmdb-object-usage-messaging.limits-banner.agent-limit-reached-yearly',
		defaultMessage:
			'<b>You’re out of available Assets objects.</b> Contact your admin to increase the usage limit.',
		description:
			'Banner message to display at top of page when agent, on yearly payment cycle, is at 100% usage',
	},
	warningIcon: {
		id: 'servicedesk-cmdb-object-usage-messaging.limits-banner.warning-icon',
		defaultMessage: 'Warning icon',
		description: 'Warning icon showing at start of banner text',
	},
	errorIcon: {
		id: 'servicedesk-cmdb-object-usage-messaging.limits-banner.error-icon',
		defaultMessage: 'Error icon',
		description: 'Error icon showing at start of banner text',
	},
	dismissBanner: {
		id: 'servicedesk-cmdb-object-usage-messaging.limits-banner.dismiss-banner',
		defaultMessage: 'Dismiss banner',
		description: 'Label text describing dismiss banner button',
	},
});
