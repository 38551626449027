export const OPERATION_TYPE_BULK_EDIT = 'BulkEdit';
export const OPERATION_TYPE_BULK_DELETE = 'BulkDelete';
export const OPERATION_TYPE_BULK_EXPORT = 'BulkExport';
export const OPERATION_TYPE_BULK_QR_CODE = 'QrCode';

export const STATUS_QUEUED = 'Queued';
export const STATUS_IN_PROGRESS = 'InProgress';
export const STATUS_COMPLETED = 'Completed';

export type TaskOperationType =
	| typeof OPERATION_TYPE_BULK_EDIT
	| typeof OPERATION_TYPE_BULK_DELETE
	| typeof OPERATION_TYPE_BULK_EXPORT
	| typeof OPERATION_TYPE_BULK_QR_CODE;

export type TaskStatus = typeof STATUS_QUEUED | typeof STATUS_IN_PROGRESS | typeof STATUS_COMPLETED;

export type TaskLink = {
	rel: string;
	href: string;
	type: string;
};

export type TaskId = string;

export const toTaskId = (id: string): TaskId => id;

type BaseTask = {
	identifier: TaskId;
	links?: TaskLink[];
};

type ToOperationType<T extends TaskOperationType> = BaseTask & { operationType: T };
type ToCompletedOperationType<T extends TaskOperationType> = BaseTask & {
	status: typeof STATUS_COMPLETED;
	operationType: T;
	// TODO: Confirm if these are always present once development begins
	itemRequestedCount?: number;
	itemSucceededCount?: number;
	hasError?: boolean;
};

type BulkEditTask = ToOperationType<typeof OPERATION_TYPE_BULK_EDIT>;
type BulkDeleteTask = ToOperationType<typeof OPERATION_TYPE_BULK_DELETE>;
type BulkExportTask = ToOperationType<typeof OPERATION_TYPE_BULK_EXPORT>;
type BulkQrCodeTask = ToOperationType<typeof OPERATION_TYPE_BULK_QR_CODE>;

type BaseTasks = BulkEditTask | BulkDeleteTask | BulkExportTask | BulkQrCodeTask;

export type CompletedBulkEditTask = ToCompletedOperationType<typeof OPERATION_TYPE_BULK_EDIT>;
export type CompletedBulkDeleteTask = ToCompletedOperationType<typeof OPERATION_TYPE_BULK_DELETE>;
export type CompletedBulkExportTask = ToCompletedOperationType<typeof OPERATION_TYPE_BULK_EXPORT>;
export type CompletedBulkQrCodeTask = ToCompletedOperationType<typeof OPERATION_TYPE_BULK_QR_CODE>;

type CompletedTasks =
	| CompletedBulkEditTask
	| CompletedBulkDeleteTask
	| CompletedBulkExportTask
	| CompletedBulkQrCodeTask;

export type CompletedTask = CompletedTasks;

export type CompletedTaskWithResults = CompletedBulkEditTask | CompletedBulkDeleteTask;

type PendingTask = BaseTasks & {
	status: typeof STATUS_QUEUED | typeof STATUS_IN_PROGRESS;
};

export type Task = PendingTask | CompletedTask;

export type ErroneousObject = {
	objectId: string;
	error: string;
};

export type BulkActionResponse = {
	taskId: TaskId;
	links: TaskLink[];
};
